import React from "react";
import { Typography } from "antd";
import isPlainObject from "lodash/isPlainObject";
import { isExist } from "../../utils";
const { Paragraph, Link } = Typography;

export interface MultiLineTextEllipsisProps {
  text: string | React.ReactElement;
  style?: React.CSSProperties;
  rows?: number;
  ellipsis?: any;
  tooltip?: any;
  type?: any;
  overlayStyle?: React.CSSProperties;
  tooltipContent?: any;
}
/**
 *
 * @param {
 * 多行文本显示省略号，Hover显示全部的升级版本组件
 * } props
 * @returns
 */
function MultiLineTextEllipsis(props: MultiLineTextEllipsisProps) {
  const {
    text,
    style = {},
    rows = 1,
    ellipsis = {},
    tooltip = null,
    type = "",
    overlayStyle = {},
    tooltipContent = {},
  } = props;

  if (isPlainObject(text)) {
    // console.log(text)
  }
  const Dom = type === "link" ? Link : Paragraph;
  return (
    <Dom
      style={{
        margin: 0,
        ...style,
      }}
      ellipsis={{
        rows,
        tooltip: {
          text: tooltip ?? text,
          overlayStyle: overlayStyle,
          ...tooltipContent,
        },
        ...ellipsis,
      }}
    >
      {isPlainObject(text) &&
      !isExist((text as React.ReactElement)?.props?.children)
        ? ""
        : text}
       {" "}
    </Dom>
  );
}

export default MultiLineTextEllipsis;
