import "./switch.css"; // 引入样式文件
/**
 *@description:自定义Switch按钮
 *@author: 清欢
 *@param:
 *@createTime: 2025-02-24 19:39:31
 */
function CustomSwitch(props: { onChange: (value: boolean) => void; value?: boolean }) {
  const { onChange, value } = props;
  // 切换开关状态
  const toggleSwitch = () => {
    onChange(!value);
  };
  return (
    <div className="switch-container">
      <label className="switch">
        <input type="checkbox" checked={value} onChange={toggleSwitch} />
        <span className="slider"></span>
      </label>
    </div>
  );
}
export default CustomSwitch;
