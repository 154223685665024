import React from "react";

export const CompanySvg = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="139" height="20" viewBox="0 0 139 20" fill="none">
      <g clipPath="url(#clip0_22837_59140)">
        <path
          d="M82.0351 0C83.3877 0 84.6803 0.264741 85.8773 0.78219C87.0982 1.29964 88.1395 2.00963 89.0132 2.92419C89.899 3.83875 90.6171 4.87365 91.1438 6.07701C91.6585 7.30445 91.9218 8.58002 91.9218 9.93983C91.9218 11.2996 91.6585 12.5993 91.1438 13.8026C90.6291 15.006 89.9229 16.077 89.0132 16.9555C88.1036 17.846 87.0742 18.568 85.8773 19.0975C84.6564 19.6149 83.3877 19.8797 82.0351 19.8797C80.6826 19.8797 79.3899 19.6149 78.193 19.0975C76.996 18.58 75.9308 17.87 75.057 16.9555C74.1713 16.0409 73.4531 15.006 72.9264 13.8026C72.4118 12.5993 72.1484 11.2996 72.1484 9.93983C72.1484 8.58002 72.4118 7.28038 72.9264 6.07701C73.4411 4.84958 74.1473 3.80265 75.057 2.92419C75.9667 2.03369 76.996 1.31167 78.193 0.78219C79.3899 0.264741 80.6826 0 82.0351 0ZM82.0351 16.0289C82.861 16.0289 83.639 15.8724 84.3931 15.5596C85.1472 15.2467 85.7696 14.8014 86.3321 14.2599C86.8947 13.7184 87.3136 13.0566 87.6248 12.3345C87.936 11.6125 88.0916 10.8183 88.0916 9.98796C88.0916 9.15764 87.936 8.37545 87.6248 7.61733C87.3136 6.8592 86.8707 6.23345 86.3321 5.66787C85.7815 5.11432 85.1352 4.68111 84.3931 4.36823C83.651 4.05535 82.861 3.89892 82.0351 3.89892C81.2092 3.89892 80.4312 4.05535 79.7011 4.36823C78.971 4.68111 78.3246 5.12635 77.786 5.66787C77.2354 6.22142 76.8045 6.87124 76.4933 7.61733C76.1821 8.37545 76.0265 9.15764 76.0265 9.98796C76.0265 10.8183 76.1821 11.6005 76.4933 12.3345C76.8045 13.0686 77.2474 13.7184 77.786 14.2599C78.3366 14.8135 78.9829 15.2467 79.7011 15.5596C80.4193 15.8845 81.1973 16.0289 82.0351 16.0289Z"
          fill="#18191A"
        />
        <path
          d="M6.7017 0.517693C6.83336 0.156682 7.09669 0.000244141 7.4797 0.000244141H10.3523C10.7114 0.000244141 10.9508 0.18075 11.0825 0.517693L17.7255 18.8209C17.7973 19.0857 17.7733 19.3143 17.6177 19.5309C17.4621 19.7596 17.2587 19.8679 16.9714 19.8679H14.41C14.2544 19.8679 14.0988 19.8197 13.9671 19.7114C13.8354 19.6031 13.7397 19.4828 13.6798 19.3264L12.5906 16.2698C12.459 15.9088 12.2315 15.7523 11.8605 15.7523H5.89975C5.54067 15.7523 5.27735 15.9329 5.16962 16.2698L4.08041 19.3264C4.03253 19.4828 3.92481 19.6152 3.79315 19.7114C3.66148 19.8077 3.50588 19.8679 3.35028 19.8679H0.788841C0.501577 19.8679 0.298098 19.7596 0.142496 19.5309C-0.0131051 19.3023 -0.0370438 19.0616 0.0347722 18.8209L6.7017 0.517693ZM7.04881 10.999C6.96502 11.2638 6.98896 11.5285 7.14456 11.7331C7.30016 11.9617 7.50364 12.07 7.79091 12.07H9.99327C10.2805 12.07 10.484 11.9617 10.6396 11.7331C10.7952 11.5285 10.8192 11.2638 10.7473 10.999L9.38283 7.12419C9.29904 6.88352 9.1195 6.77521 8.88012 6.77521C8.6527 6.77521 8.46119 6.88352 8.38937 7.11216L7.04881 10.999Z"
          fill="#18191A"
        />
        <path
          d="M23.305 0.22864C23.4606 0.0722022 23.6402 0 23.8556 0H36.7945C37.0219 0 37.2134 0.0722022 37.369 0.22864C37.5246 0.385078 37.5964 0.565584 37.5964 0.806257V2.92419C37.5964 3.15283 37.5246 3.34537 37.369 3.50181C37.2134 3.65824 37.0339 3.73044 36.7945 3.73044H33.1079C32.9045 3.73044 32.7249 3.80265 32.5573 3.95909C32.3898 4.11552 32.3299 4.29603 32.3299 4.5367V19.1336C32.3299 19.3622 32.2581 19.5548 32.1025 19.7112C31.9469 19.8676 31.7674 19.9398 31.528 19.9398H29.1461C28.9426 19.9398 28.7631 19.8676 28.5955 19.7112C28.4399 19.5548 28.3681 19.3742 28.3681 19.1336V4.51264C28.3681 4.284 28.2963 4.09146 28.1407 3.93502C27.9851 3.77858 27.8055 3.70638 27.5661 3.70638H23.8796C23.6761 3.70638 23.4966 3.63418 23.329 3.47774C23.1734 3.30927 23.1016 3.12876 23.1016 2.88809V0.806257C23.1016 0.577617 23.1734 0.385078 23.329 0.22864H23.305Z"
          fill="#18191A"
        />
        <path
          d="M54.5805 14.2118C54.5805 14.2118 54.76 14.3201 54.8677 14.3201C54.9755 14.3201 55.0712 14.296 55.1789 14.2118C55.2867 14.1637 55.3345 14.0554 55.3824 13.923L59.9427 0.517449C60.0744 0.156438 60.3377 0 60.7207 0H63.3181C63.5455 0 63.737 0.0722022 63.8926 0.22864C64.0482 0.385078 64.12 0.565584 64.12 0.806257V19.1095C64.12 19.3381 64.0482 19.5307 63.8926 19.6871C63.737 19.8436 63.5575 19.9158 63.3181 19.9158H60.9362C60.7327 19.9158 60.5532 19.8436 60.3856 19.6871C60.23 19.5307 60.1582 19.3502 60.1582 19.1095V13.9711C60.1582 13.8387 60.1103 13.7064 60.0265 13.6342C59.9547 13.562 59.847 13.4777 59.7153 13.4537C59.6076 13.4296 59.4879 13.4537 59.3802 13.5018C59.2725 13.5499 59.2006 13.6582 59.1528 13.7906L57.1299 19.3983C56.9983 19.7593 56.7469 19.9158 56.3998 19.9158H53.4194C53.2638 19.9158 53.1082 19.8676 52.9766 19.7593C52.8449 19.651 52.7492 19.5307 52.6893 19.3742L50.6665 13.7665C50.6186 13.6342 50.5348 13.5379 50.4391 13.4777C50.3314 13.4296 50.2356 13.4055 50.1039 13.4296C49.9723 13.4537 49.8765 13.5018 49.7927 13.6101C49.7209 13.6823 49.6611 13.8147 49.6611 13.9471V19.0854C49.6611 19.3141 49.5893 19.5066 49.4337 19.6631C49.2781 19.8195 49.0985 19.8917 48.8591 19.8917H46.4772C46.2737 19.8917 46.0942 19.8195 45.9266 19.6631C45.771 19.5066 45.6992 19.3261 45.6992 19.0854V0.806257C45.6992 0.577617 45.771 0.385078 45.9266 0.22864C46.0703 0.0722022 46.2498 0 46.4533 0H49.0746C49.4337 0 49.697 0.180505 49.8047 0.517449L54.389 13.923C54.389 13.923 54.5206 14.1276 54.5924 14.2118H54.5805Z"
          fill="#18191A"
        />
        <path
          d="M109.876 16.0289C110.606 16.0289 111.276 15.9206 111.923 15.692C112.569 15.4633 113.144 15.1384 113.658 14.7052C113.814 14.5728 113.993 14.5247 114.173 14.5488C114.376 14.5488 114.532 14.6571 114.664 14.7774L116.292 16.3899C116.471 16.5704 116.555 16.7509 116.519 16.9916C116.519 17.2203 116.411 17.4128 116.232 17.5692C115.382 18.3033 114.388 18.8689 113.299 19.2901C112.21 19.7112 111.073 19.9158 109.852 19.9158C108.476 19.9158 107.207 19.6511 105.986 19.1336C104.765 18.6162 103.724 17.9062 102.826 16.9916C101.94 16.1011 101.222 15.0422 100.719 13.8147C100.205 12.5873 99.9414 11.3117 99.9414 9.92784C99.9414 8.54397 100.205 7.24433 100.743 6.04096C101.258 4.83759 101.988 3.76659 102.898 2.88813C103.808 1.99764 104.837 1.27561 106.058 0.746132C107.315 0.240717 108.583 -0.0240245 109.96 4.2868e-05C111.181 4.2868e-05 112.294 0.228683 113.359 0.649862C114.424 1.07104 115.382 1.63663 116.268 2.37068C116.447 2.50305 116.555 2.68356 116.555 2.92423C116.555 3.1649 116.483 3.34541 116.327 3.52591L114.7 5.1625C114.544 5.29487 114.364 5.36707 114.185 5.36707C113.981 5.36707 113.826 5.31894 113.67 5.21063C113.156 4.78945 112.581 4.45251 111.935 4.22387C111.288 3.99523 110.606 3.88693 109.888 3.88693C109.062 3.88693 108.26 4.04336 107.53 4.38031C106.776 4.69318 106.153 5.13843 105.615 5.67995C105.064 6.2335 104.633 6.88332 104.322 7.60534C104.011 8.36346 103.855 9.14565 103.855 9.97597C103.855 10.8063 104.011 11.5885 104.322 12.3466C104.633 13.0807 105.076 13.7305 105.615 14.272C106.153 14.8135 106.812 15.2588 107.53 15.5716C108.26 15.8845 109.038 16.041 109.888 16.041L109.876 16.0289Z"
          fill="#18191A"
        />
        <path
          d="M126.036 0.22864C126.192 0.0722022 126.371 0 126.587 0H138.209C138.436 0 138.628 0.0722022 138.783 0.22864C138.939 0.385078 139.011 0.565584 139.011 0.806257V2.92419C139.011 3.15283 138.939 3.34537 138.783 3.50181C138.628 3.65824 138.448 3.73044 138.209 3.73044H130.56C130.357 3.73044 130.177 3.80265 130.01 3.95909C129.854 4.11552 129.782 4.29603 129.782 4.5367V7.32852C129.782 7.55716 129.854 7.7497 130.01 7.90614C130.165 8.06258 130.345 8.13478 130.56 8.13478H135.564C135.791 8.13478 135.983 8.20698 136.138 8.36342C136.294 8.51985 136.366 8.70036 136.366 8.94103V11.059C136.366 11.2876 136.294 11.4801 136.138 11.6366C135.983 11.793 135.803 11.8652 135.564 11.8652H130.56C130.357 11.8652 130.177 11.9374 130.01 12.0939C129.854 12.2503 129.782 12.4308 129.782 12.6715V15.4633C129.782 15.6919 129.854 15.8845 130.01 16.0409C130.165 16.1974 130.345 16.2696 130.56 16.2696H138.209C138.436 16.2696 138.628 16.3418 138.783 16.4982C138.939 16.6546 139.011 16.8351 139.011 17.0758V19.1937C139.011 19.4224 138.939 19.6149 138.783 19.7714C138.628 19.9278 138.448 20 138.209 20H126.587C126.383 20 126.204 19.9278 126.036 19.7714C125.868 19.6149 125.809 19.4344 125.809 19.1937V0.806257C125.809 0.577617 125.88 0.397112 126.036 0.22864Z"
          fill="#18191A"
        />
      </g>
      <defs>
        <clipPath id="clip0_22837_59140">
          <rect width="139" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
