import { Pagination, Table } from "antd";
import { forwardRef, useCallback, useState, useImperativeHandle, useRef } from "react";
import CustomSearchList from './searchList';
import { CustomTableListType, ResponseType, tableColumnsType } from "./config";
import React, { useEffect, Ref } from "react";
import usePagination from "../../hooks/usePagination";
import GlobalNoData from "../GlobalNoData";
import { divide, isArray } from "lodash";
import "./index.css"
export interface RefProps {
  fetchData?: () => void;
}

const CustomTableList = (props: CustomTableListType, ref: Ref<RefProps>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { searchListData, isPage, isSearch, tableColumns } = props
  const [response, setResponse] = useState<ResponseType>({
    total: 0,
    data: []
  })
  const [isLoading, setIsLoading] = useState(true)
  // 合并管理分页和搜索参数
  const [queryParams, setQueryParams] = useState<{
    page: number
    page_size: number
    search?: Record<string, any>
  }>({
    page: 1,
    page_size: 20,
  })
  // 使用分页 Hook
  const { paginationState, paginationProps, resetPagination } = usePagination({
    total: response.total,
    initialCurrent: queryParams.page,
    initialPageSize: queryParams.page_size,
    pageSizeOptions: ['10', '20', '50'],
    onChange: (page: number, page_size: number) => {
      // 分页变化时更新查询参数
      setQueryParams((prev) => ({
        ...prev,
        page,
        page_size,
      }));
    },
  });
  // 统一请求方法
  const fetchData = useCallback(async (type?: string) => {
    console.log(queryParams, '12121')
    console.log(response, '12121')
    try {
      // 判断是否需要回退到上一页
      setIsLoading(true)
      let adjustedPage = queryParams.page;
      if (type === 'delete') {
        // 因为是删除条件，所以总条数 -1 再去对比
        const isLastPageEmpty = (queryParams.page - 1) * queryParams.page_size >= response.total - 1;
        adjustedPage = isLastPageEmpty ? queryParams.page - 1 : queryParams.page;
      }
      const res: any = await props.searchListData.onQuery({
        page: adjustedPage,
        page_size: queryParams.page_size,
        search: queryParams.search
      })
      console.log(res, '32432432')
      // if (res.total <= )
      setResponse({
        data: res.response ?? [], // 假设返回的数据属性是 data
        total: res.total ?? 0
      })
    } catch (error) {
      console.error('Fetch data error:', error)
    } finally {
      setIsLoading(false)
    }
  }, [queryParams])
  const handleClickConfirm = (data: any) => {
    setQueryParams((prev) => ({
      ...prev,
      page: 1,
      search: data.data,
    }));
  }
  /**
   * @handleClickCancel
   * @SearchList 组件点击重置的回调函数
   */
  const handleClickCancel = async () => {
    setQueryParams({
      page: 1,
      page_size: queryParams.page_size,
      search: undefined,
    });
    resetPagination();
  }
  const getHeight = () => {
    const dom = document.getElementsByClassName("table-list")
    const searchDom = document.getElementsByClassName("search-list")
    // 获取dom当前视图高度
    const height = dom[0]?.clientHeight || 0
    const searchHeight = searchDom[0]?.clientHeight || 0
    // 计算表格高度
    return height - 140 - ( isSearch ? searchHeight : 0)
  }
  // 监听查询参数变化
  useEffect(() => {
    fetchData()
  }, [queryParams])
  const getWidth = (item: tableColumnsType, key: number) => {
    console.log(item, 'oooos')
    console.log(key, 'key')
    const rowDomParent = document.getElementsByClassName("ant-table-thead")[0]
    console.log(rowDomParent, "rowDomParentrowDomParent")
    if (rowDomParent) {
      const elements = rowDomParent.querySelectorAll('.ant-table-cell');
      const currentValue = elements[key].textContent || ''
      return currentValue.length * 18
    }
    return 100
  }
  useImperativeHandle(ref, () => ({
    fetchData,
  }));

  console.log(paginationProps, "paginationProps")
  return <div className="h-full relative table-list" ref={containerRef}>
    {isSearch && <div className="mb-4 search-list">
      <CustomSearchList 
        data={{
          ...searchListData,
        }}
        handleClickConfirm={handleClickConfirm} 
        handleClickCancel={handleClickCancel}  
      />
    </div>}
    {
      <Table
        dataSource={response.data || []} 
        scroll={{ y: getHeight() }}
        pagination={false}
        loading={isLoading}
          locale={{
            emptyText:
              isLoading ? <div
                style={{
                  height: 300
                }}
              ></div> : <GlobalNoData />
          }}
        {...props}
      >
        {tableColumns.map((item: tableColumnsType, key) => {
          return (
            <Table.Column
              dataIndex={item.key}
              className={`rowClassName${item.key}`}
              // width={getWidth(item, key)}
              // minWidth={getWidth(item)}
              // width={getWidth(item)}
              {...item}
            />
          )
        })}
      </Table>
    }

    {isPage && (
      <div className="absolute right-3 bottom-4" >
        <Pagination {...paginationProps}  className="pr-[24px] flex items-center" /> {/* 直接传递分页组件的 props */}
      </div>
    )}
  </div>;
};

export default forwardRef(CustomTableList);
