import { getInterfaceTypeList, stationList } from "../model/services";
import { CardList, ParamsType, StationType } from "../model/type";
import { AUTH_TOKEN } from "common/config";
import { useEffect, useState } from "react";
import { message } from "antd";
export const useData = (isRequset: boolean = true) => {
  const [dataCard, setDataCard] = useState<CardList[]>([]);
  const [stationData, setStationData] = useState<StationType[]>([]);
  const [stationINterFaceStatus, setStationINterFaceStatus] = useState<boolean>(false);
  useEffect(() => {
    if (isRequset) {
      getStationList();
    }
  }, []);
  // 请求电站列表
  const getStationList = async () => {
    const res: any = await stationList();
    if (!res || res.code !== 200) {
      setStationINterFaceStatus(true);
      return message.error(res.msg);
    }
    const data = res?.data ?? [];
    if (data.length === 1) {
      data[0].oauthSelected = true;
    }
    await getAuthInfos(data);
  };
  const getAuthInfos = async (data: StationType[]) => {
    const typeListRes: any = await getInterfaceTypeList(); // 基础类 控制类列表接口
    if (!typeListRes || typeListRes.code !== 200) {
      return message.error(typeListRes.msg);
    }
    setDataCard(typeListRes.data);
    setStationData(data);
    setStationINterFaceStatus(true);
  };

  /**
   *@description:url地址解析
   *@author: 清欢
   *@param:
   *@createTime: 2025-02-24 17:04:20
   */
  const urlFunc = () => {
    const obj: { [key in ParamsType]?: string } = {};
    const search = window.location.href ?? [];
    const key = search.split("?")[1]?.split("&") ?? [];
    for (let i = 0; i < key.length; i++) {
      const ele = key[i];
      const [k, val] = ele.split("=");
      obj[k as ParamsType] = val;
    }
    return obj;
  };
  // 跳转到下一页
  const goNextPage = ({ result }: any) => {
    return new Promise((resolve, reject) => {
      fetch(`/permission-auth/authorize/save`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
        body: JSON.stringify({
          stationAuthorizeInfo: result,
        }),
      })
        .then((r: any) => {
          resolve(r);
        })
        .catch((res: any) => {
          reject(res);
        });
    });
  };
  return { dataCard, urlFunc, goNextPage, setDataCard, stationData, setStationData, stationINterFaceStatus };
};
