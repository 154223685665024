import React from "react";

const DownloadSvg = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3 18.1053H20.0526V20H3V18.1053ZM12.4737 12.584L18.2252 6.83158L19.5647 8.17116L11.5263 16.2105L3.48789 8.17211L4.82747 6.83158L10.5789 12.5821V2H12.4737V12.584Z"
        fill={props?.color || "#2E3230"}
        fillOpacity="0.8"
      />
    </svg>
  );
};

export default DownloadSvg;
