interface OptionsType {
  label: string,
  value: string | number
}
interface tableColumnsType {
  title: string | React.ReactNode,
  key: string,
  width?: string | number
  render?: {
    (text: any, record: any): React.ReactNode;
  },
}
interface CustomTableListType {
  tableColumns: tableColumnsType[],
  slots?: {},
  isPage: boolean,
  isSearch: boolean,
  searchListData: {
    list: {
      label?: string,
      placeholder?: string;
      value: string,
      type: "input" | "select"
      options?: OptionsType[]
      allowClear?: boolean,
    }[],
    onQuery: (params: any) => void,
    slots?: React.ReactNode
  }
}
interface ResponseType {
  total: number,
  data: any[]
}
/**
 * 此处做集中引入
 */
import { Select, Input, } from "antd";
const getComponents: any = {
  input: Input,
  select: Select,
  option: Select.Option,
  // date: ElDatePicker
}
export {
  type CustomTableListType,
  type ResponseType,
  type OptionsType,
  type tableColumnsType,
  getComponents
}