import { Flex , Button } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getComponents } from './config'
import { CustomTableListType, ResponseType, OptionsType } from "./config";

import React from "react";
interface CustomSearchListType {
  data: CustomTableListType["searchListData"],
  handleClickConfirm: (res: any) => void
  handleClickCancel: () => void
}
interface paramsType {
  [key: string]: string | number
}
const CustomSearchList = (props: CustomSearchListType) => {
  const { list } = props.data
  const [params, setParams] = useState<paramsType>({})
  /**
   * 点击确定
   */
  const handleClick = async () => {
    // 为input框去除前后空格
    const isInput = list.filter((item) => item.type === 'input')?.map((ele) => ele.value) || []
    isInput.forEach((item) => params[item] = (params[item] as string)?.trim())
    const state = {
      data: {...params}
    }
    props.handleClickConfirm(state)
  }
  /**
   * 点击重置
   */
  const handleClickReset = () => {
    setParams({})
    props.handleClickCancel()
  }
  const getDom = (item: CustomTableListType["searchListData"]["list"][0]) => {
    return React.createElement(getComponents[item.type], 
      {
        className: "w-[220px]",
        placeholder: item.placeholder,
        value: params[item.value],
        allowClear: item.allowClear,
        // 'onUpdate:value': (value: string) => {
        //   console.log(value, '1212')
        //     params[item.value] = value
        // }
        onChange: (value: string | number | Event) => {
          let finalValue: string | number;
          // 针对 Input 组件特殊处理
          if (value && (value as Event).target) {
            finalValue = ((value as Event).target as HTMLInputElement).value;
          } else {
            finalValue = value as string | number;
          }

          params[item.value] = finalValue;
          setParams({ ...params });
        }
        // options: 
      }, 
      item.type === "select" && item.options ? item.options.map((option: OptionsType) => {
        return React.createElement(
          getComponents[item.type === 'select' ? 'option' : ''],
          {
            key: option.value,
            value: option.value,
            label: option.label
          },
          option.label
        )
      }) : null
    );
  }
  return <Flex justify="space-between" className="w-full pt-6 rounded-t-[8px] px-[1.5rem]">
    <Flex wrap gap="middle" >
      {Array.isArray(list) && list.map((item) => {
        return (
          <div className="whitespace-nowrap items-center" key={item.value}>
            {
              item.label && <span className="text-gray-500">{item.label}: </span>
            }
            {getDom(item)}
          </div>
        )
      })}
      <Button type="primary" onClick={() => handleClick()}>
        查询
      </Button>
      <Button onClick={() => handleClickReset()}>重置</Button>
    </Flex>
    {props.data.slots && props.data.slots}
  </Flex>

};

export default CustomSearchList;
