export const NoOauthSvg = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="86" height="86" viewBox="0 0 86 86" fill="none">
      <path
        d="M75.4466 15.8572L45.2131 6.25781L14.9896 15.8572C13.8533 16.2158 13.0857 17.2732 13.0958 18.4691V56.677C13.1563 56.9365 14.5815 63.2261 20.7611 69.3166C25.5154 74.0013 36.9196 80.023 41.894 82.634C42.6709 83.043 43.0404 83.1925 43.3688 83.3714L44.8444 84.1088C45.2333 84.3179 45.9312 84.3087 46.32 84.1088L47.7956 83.3714C50.7057 81.9462 64.1726 74.7294 69.6652 69.3166C75.8556 63.2261 77.2809 56.9365 77.3405 56.677V18.4691C77.3598 17.2732 76.583 16.2158 75.4466 15.8572ZM45.5919 78.1879L45.5717 78.1778H45.6213C45.602 78.1879 45.5919 78.1879 45.5919 78.1879ZM52.7986 55.9892H47.914V62.8675C47.914 64.4623 46.6182 65.7582 45.0233 65.7582C43.4284 65.7582 42.1326 64.4623 42.1326 62.8675V45.1241C42.1326 45.0645 42.1426 45.0149 42.1426 44.9645C41.6144 44.805 41.0861 44.6152 40.5579 44.3766C38.774 43.5595 37.2489 42.0939 36.2318 40.4294C35.1753 38.6951 34.6168 36.5216 34.7864 34.4883C34.9855 32.1662 35.8228 30.1421 37.3085 28.3381C39.8801 25.2181 44.6051 23.9525 48.3928 25.3374C50.536 26.1252 52.2804 27.4412 53.5964 29.2947C54.7529 30.9198 55.3408 32.943 55.3912 34.9267C55.3912 34.9863 55.4012 35.0359 55.3912 35.0964C55.3912 35.1661 55.3912 35.2358 55.3811 35.3055C55.2417 39.7113 52.2115 43.8677 47.9048 45.0149V50.0884H52.7893C54.3842 50.0884 55.6801 51.3843 55.6801 52.9792V53.0984H55.6902C55.6893 54.6933 54.3934 55.9892 52.7986 55.9892Z"
        fill="url(#paint0_linear_29264_108399)"
      />
      <path
        d="M48.5429 37.717C48.5723 37.6675 48.6025 37.6271 48.6126 37.6171C48.7844 37.3536 48.9409 37.0805 49.0812 36.7991C49.2106 36.4304 49.3105 36.0617 49.3903 35.6728C49.4004 35.4839 49.4104 35.284 49.4205 35.0942C49.4205 34.9044 49.4104 34.7053 49.3903 34.5164C49.3206 34.1376 49.2106 33.7588 49.0812 33.3901C48.9649 33.1555 48.8385 32.926 48.7025 32.7023C48.6722 32.6721 48.6025 32.573 48.4833 32.3832C48.3741 32.2631 48.2641 32.1337 48.1448 32.0245C48.0054 31.8851 47.8559 31.7457 47.6963 31.6155C47.6468 31.5861 47.6073 31.5559 47.5964 31.5458C47.3332 31.374 47.0604 31.2176 46.7792 31.0772C46.4105 30.9479 46.0418 30.8479 45.6631 30.7681C45.2742 30.7387 44.8854 30.7387 44.4965 30.7681C44.1177 30.8378 43.739 30.9479 43.3804 31.0772C43.1458 31.1935 42.9163 31.3199 42.6925 31.456C42.6623 31.4862 42.5632 31.5559 42.3734 31.6752C42.2533 31.7844 42.124 31.8944 42.0148 32.0136C41.8754 32.153 41.736 32.3025 41.6058 32.4621C41.5764 32.5117 41.5461 32.552 41.5361 32.562C41.3643 32.8252 41.2078 33.098 41.0674 33.3792C40.9381 33.7479 40.8382 34.1166 40.7584 34.4954C40.729 34.8842 40.729 35.2731 40.7584 35.6619C40.8281 36.0407 40.9381 36.4195 41.0674 36.7781C41.1867 37.0174 41.3068 37.2366 41.4462 37.4659C41.4764 37.4961 41.5461 37.5952 41.6654 37.785C41.7746 37.9051 41.8846 38.0345 42.0039 38.1436C42.1433 38.2831 42.2928 38.4225 42.4523 38.5527C42.5019 38.582 42.5422 38.6123 42.5523 38.6224C42.811 38.792 43.0906 38.9516 43.3695 39.091C43.7381 39.2203 44.1068 39.3203 44.4856 39.4001C44.8744 39.4303 45.2633 39.4303 45.6521 39.4001C46.0309 39.3303 46.4097 39.2203 46.7683 39.091C47.0077 38.9717 47.2269 38.8516 47.4561 38.7122C47.4864 38.682 47.5855 38.6123 47.7753 38.493C47.9046 38.3931 48.0247 38.2839 48.1339 38.1638C48.2741 38.0261 48.4135 37.8766 48.5429 37.717Z"
        fill="#90AFE9"
      />
      <defs>
        <linearGradient id="paint0_linear_29264_108399" x1="13.2125" y1="45.505" x2="75.5654" y2="35.1229" gradientUnits="userSpaceOnUse">
          <stop stopColor="#8CACE9" />
          <stop offset="1" stopColor="#ADC2ED" />
        </linearGradient>
      </defs>
    </svg>
  );
};
