export const BottomSvg = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="237" height="52" viewBox="0 0 237 52" fill="none">
      <ellipse cx="118.31" cy="26.3386" rx="118.31" ry="25.6628" fill="url(#paint0_linear_29228_114406)" />
      <defs>
        <linearGradient id="paint0_linear_29228_114406" x1="118.31" y1="0.675781" x2="118.31" y2="52.0014" gradientUnits="userSpaceOnUse">
          <stop stopColor="#96B2EA" stopOpacity="0.18" />
          <stop offset="0.533444" stopColor="#96B2EA" stopOpacity="0" />
          <stop offset="1" stopColor="#96B2EA" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
