const useValidation = {
  validateSTNumber: (str: string) => {
    const pattern = /^MW\d{6}$/;
    return pattern.test(str);
  },
  validateEmail: (str: string) => {
    const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/;
    return re.test(String(str).toLowerCase());
  },
  validatePhone: (str: string) => {
    return !!str && str.length === 11;
  },
  validateUsername: (str: string) => {
    const re = /^[a-zA-Z0-9-]{6,12}$/;
    return re.test(str);
  },
  validatePassword: (str: string) => {
    const re =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
    return re.test(str);
  },
  validateSteinPwd: (str: string) => {
    const re =
      /^((?=.*[A-Za-z])(?=.*\d)|(?=.*[A-Za-z])(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])|(?=.*\d)(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]))[A-Za-z\d`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{6,20}$/;
    return re.test(str);
  },
  validateUppercase: (str: string) => {
    const re = /^[A-Z]/g;
    return re.test(str);
  },
  validateLowercase: (str: string) => {
    const re = /[a-z]/g;
    return re.test(str);
  },
  validateSpecial: (str: string) => {
    const re = /^(?=.*[@$!%*?&])[0-9A-Za-z\d@$!%*?&]{0,}$/;
    return re.test(str);
  },
  validateNoChinese: (str: string) => {
    // const re = /^[0-9a-zA-Z]*$/g
    let re = new RegExp("[\u4E00-\u9FA5]+");
    return re.test(str);
  },

  validateString: (value: any) => {
    return typeof value === "string";
  },
  validateNumer: (value: any) => {
    return typeof value === "number";
  },
  validateStringIsNumer: (value: string) => {
    const res = /^[0-9]*$/g;
    return res.test(value);
  },
  validateObject: (value: any) => {
    return typeof value === "object";
  },
  validateArray: (value: any) => {
    return Array.isArray(value);
  },
  isRequired: (value: any) => {
    if (value) {
      if (typeof value === "object") {
        return Object.keys(value).length > 0;
      }
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return true;
    }
    return false;
  },
  validateConsecutiveNumber: (arr: any[]) => {
    const differenceAry = arr.slice(1).map((n: any, i: number) => {
      return n - arr[i];
    });
    const isDifference = differenceAry.every((value) => value === 1);

    return isDifference;
  },
  validateSameValue: (arr: string[]) => {
    return (
      arr.filter((item, index) => arr.indexOf(item) !== index).length ===
      arr.length - 1
    );
  },
};
export default useValidation;
