import { Component } from "react";
import { withTranslation } from "react-i18next";
import "./index.css";
import { appUrl } from "./services";

type StateTy = {
  title: boolean;
  station: any[];
};

class SharePage extends Component<
  {
    t: any;
  },
  StateTy
> {
  constructor(props: any) {
    super(props);

    this.state = {
      title: true,
      station: [],
    };
  }
  async autoGetAddress(terminalType: string) {
    const res = await appUrl({ terminalType });
    return res.data;
  }

  getTokenFunc = async () => {
    const [, urlParams] = window.location.href.split("?");
    const [, code] = urlParams.split("=");
    if (code) {
      fetch("/openapi/v1/auth/auth_token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // 设置请求头，指定发送的数据格式为 JSON
        },
        body: JSON.stringify({
          grant_type: "authorization_code",
          OAuth_Client: "9IoZa3pO6kpMdOeqlawlDDVABZAZXBZZ",
          OAuth_secret: "kSUrbI8OeQvMA4NlnLmeiFj8ol9zgLCOZ4vSpCbJDXDhsZDx03w4DDVABZAZXBZA",
          Code: code,
          redirect_uri: "http://172.18.120.92/energy/oauthTest",
        }),
      })
        .then((r) => r.json())
        .then((res) => {
          fetch(`/openapi/v1/sites/getSites`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${res.data.access_token}`,
              "Content-Type": "application/json",
              "Cache-Control": "no-cache",
            },
          })
            .then((response) => response.json())
            .then((r: any) => {
              this.setState({
                station: r.data,
              });
            })
            .catch((res: any) => {
              console.log("================>>>>>>>>>>>>", res);
            });
        })
        .catch((res: any) => {
          console.log("================>>>>>>>>>>>>", res);
        });
    }
  };
  componentDidMount(): void {
    this.getTokenFunc();
  }
  render() {
    return (
      <div className="bck" style={{ height: window.innerHeight }}>
        <div className="box">
          <div
            style={{
              fontSize: 20,
              fontWeight: 600,
              textAlign: "center",
              color: "#18191A",
            }}
          >
            <br />
            <p className="des" style={{ color: "red", fontSize: 24 }}>
              本页面是专门提供Oauth测试专用测试完毕删除
            </p>
            <div>
              {this.state.station.map((v) => {
                return (
                  <div key={v.stationId} style={{ overflow: "auto", height: 200, marginTop: 10 }}>
                    <div className="p-4" style={{ backgroundColor: "#1890ff" }}>
                      {v.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {this.state.station.length ? null : (
          <div style={{ display: "flex", flexDirection: "column", width: "100%", marginTop: 50 }}>
            <div className="pl-4 pr-4">
              <div
                style={{ padding: 20, backgroundColor: "#3E7EF8", borderRadius: 10, color: "#fff", marginBottom: 20 }}
                onClick={() => {
                  fetch(
                    `/permission-auth/v1/oauth/authorize?OAuth_Client=9IoZa3pO6kpMdOeqlawlDDVABZAZXBZZ&redirect_uri=http://172.18.120.92/energy/oauthTest&response_type=code`,
                    {
                      method: "GET",
                    },
                  )
                    .then((r: any) => {
                      window.location.href = r.url;
                    })
                    .catch((res: any) => {});
                }}
              >
                第三方授权按钮（正确）
              </div>
              <div
                style={{ padding: 20, backgroundColor: "red", borderRadius: 10, color: "#fff" }}
                onClick={() => {
                  fetch(`/permission-auth/v1/oauth/authorize?redirect_uri=http://172.18.120.92/energy/oauthTest&response_type=code`, {
                    method: "GET",
                  })
                    .then((r: any) => {
                      window.location.href = r.url;
                    })
                    .catch((res: any) => {});
                }}
              >
                第三方授权按钮（错误）
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const SharePageHtml = withTranslation()(SharePage);
export default SharePageHtml;
