import React from "react";

export const WarningSvg = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.size ?? "16"}
      height={props?.size ?? "16"}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.3335 7.99992C1.3335 4.31802 4.31826 1.33325 8.00016 1.33325C11.6821 1.33325 14.6668 4.31802 14.6668 7.99992C14.6668 11.6818 11.6821 14.6666 8.00016 14.6666C4.31826 14.6666 1.3335 11.6818 1.3335 7.99992ZM8.00016 9.99992C7.63197 9.99992 7.3335 10.2984 7.3335 10.6666C7.3335 11.0348 7.63197 11.3333 8.00016 11.3333C8.36835 11.3333 8.66683 11.0348 8.66683 10.6666C8.66683 10.2984 8.36835 9.99992 8.00016 9.99992ZM8.00016 9.33325C8.36835 9.33325 8.66683 9.03478 8.66683 8.66659L8.66683 5.33325C8.66683 4.96506 8.36835 4.66659 8.00016 4.66659C7.63197 4.66659 7.3335 4.96506 7.3335 5.33325L7.3335 8.66658C7.3335 9.03477 7.63197 9.33325 8.00016 9.33325Z"
        fill={props?.color ?? "#F67234"}
      />
    </svg>
  );
};

export const ErrorSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.3335 8.00004C1.3335 4.31814 4.31826 1.33337 8.00016 1.33337C11.6821 1.33337 14.6668 4.31814 14.6668 8.00004C14.6668 11.6819 11.6821 14.6667 8.00016 14.6667C4.31826 14.6667 1.3335 11.6819 1.3335 8.00004ZM5.88102 5.88099C5.62067 6.14134 5.62067 6.56345 5.88102 6.8238L7.05953 8.00231L5.88102 9.18082C5.62067 9.44117 5.62067 9.86328 5.88102 10.1236C6.14137 10.384 6.56348 10.384 6.82383 10.1236L8.00234 8.94512L9.18085 10.1236C9.4412 10.384 9.86331 10.384 10.1237 10.1236C10.384 9.86328 10.384 9.44117 10.1237 9.18082L8.94515 8.00231L10.1237 6.8238C10.384 6.56345 10.384 6.14134 10.1237 5.88099C9.86331 5.62064 9.4412 5.62064 9.18085 5.88099L8.00234 7.0595L6.82383 5.88099C6.56348 5.62064 6.14137 5.62064 5.88102 5.88099Z"
        fill="#EF4444"
      />
    </svg>
  );
};

export const Informational = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.3335 8.00004C1.3335 4.31814 4.31826 1.33337 8.00016 1.33337C11.6821 1.33337 14.6668 4.31814 14.6668 8.00004C14.6668 11.6819 11.6821 14.6667 8.00016 14.6667C4.31826 14.6667 1.3335 11.6819 1.3335 8.00004ZM8.00016 6.00004C8.36835 6.00004 8.66683 5.70156 8.66683 5.33337C8.66683 4.96518 8.36835 4.66671 8.00016 4.66671C7.63197 4.66671 7.3335 4.96518 7.3335 5.33337C7.3335 5.70156 7.63197 6.00004 8.00016 6.00004ZM8.00016 6.66671C7.63197 6.66671 7.3335 6.96518 7.3335 7.33337V10.6667C7.3335 11.0349 7.63197 11.3334 8.00016 11.3334C8.36835 11.3334 8.66683 11.0349 8.66683 10.6667V7.33337C8.66683 6.96518 8.36835 6.66671 8.00016 6.66671Z"
        fill="#4C77FE"
      />
    </svg>
  );
};

export const ClearAlert = (props: { color: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M11.5495 4.67114L4.88281 11.3378M4.88281 4.67114L11.5495 11.3378"
        stroke={props?.color || "#515559"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SuccessSvg = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.size ?? "24"}
      height={props?.size ?? "24"}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM10.6465 15.5607C10.8417 15.7559 11.1583 15.7559 11.3536 15.5607L16.75 10.1642C17.1405 9.7737 17.1405 9.14054 16.75 8.75001C16.3595 8.35949 15.7263 8.35949 15.3358 8.75001L11 13.0858L8.91423 11C8.5237 10.6095 7.89054 10.6095 7.50001 11C7.10949 11.3905 7.10949 12.0237 7.50001 12.4142L10.6465 15.5607Z"
        fill="#21B878"
      />
    </svg>
  );
};
