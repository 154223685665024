import { CardList as CardListType } from "../model/type";
import CustomSwitch from "./Switch/CustomSwitch";
export const CardList = (props: { onChange: (e: CardListType) => void; data: CardListType[] }) => {
  const { onChange, data } = props;
  return (
    <div className="flex flex-col">
      {data.map((v) => {
        return (
          <div className="flex justify-between bg-white p-3 rounded-md mb-3" key={v.interfaceType}>
            {/* <div className="flex flex-col justify-start items-start flex-1">{v.iconUrl}</div> */}
            <img src={v.iconUrl} alt="" style={{ width: "20px", height: "20px", marginTop: "2px" }} />
            <div className="ml-3 mr-4 flex-col justify-start items-start w-full">
              <div className="mb-1" style={{ color: "rgba(0, 4, 14, 1)", fontSize: "14px" }}>
                {v.name}
              </div>
              <div className="text-xs" style={{ color: "rgba(130, 132, 131, 1)", fontSize: "12px" }}>
                {v.describle}
              </div>
            </div>
            <div className="flex flex-col justify-start items-start flex-1 pt-1">
              <CustomSwitch
                value={!!v.oauthSelected}
                onChange={(e) => {
                  onChange({ ...v, oauthSelected: e });
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
