import React, { useMemo } from "react";
import usePolicyAndTerm, { ProtocolType } from "@/hooks/usePolicyAndTerm";
import { HighlightText } from "common";
import { t } from "i18next";
import { getTripartiteAgreement } from "@/pages/authorizationlogin/model/services";

// 跳转隐私政策，用户注册也要用
export const useToAgreement = (props?: { text?: string; type?: ProtocolType; h5TxtColor?: string }) => {
  const { text, type, h5TxtColor } = props || {};
  const { jumpToPage } = usePolicyAndTerm();
  const agreementContent = useMemo(() => {
    return (
      <HighlightText
        highlightTextStyle={{ color: "var(--color-colorLink)" }}
        style={{ color: h5TxtColor ?? "var(--color-textMain)" }}
        text={text ?? t("loginAgree")}
        clickableIndexs={[0, 1]}
        onClickIndex={async (tempIndex) => {
          if (tempIndex === 1) {
            jumpToPage("policy");
          } else {
            if (text && type) {
              const res = await getTripartiteAgreement({ agreementType: type });
              window.open(res.data.agreementUrl);
              return;
            }
            jumpToPage("term");
          }
        }}
      />
    );
  }, [jumpToPage]);

  return {
    agreementContent,
    jumpToPage,
  };
};
