import { useState, useMemo } from 'react';
import type { PaginationProps } from 'antd';
import { Pagination } from 'antd';

export interface UsePaginationOptions extends Partial<PaginationProps> {
  /** 初始页码 */
  initialCurrent?: number;
  /** 初始每页条数 */
  initialPageSize?: number;
  simple?: boolean
  // showTotal?
}

export interface PaginationResult {
  /** 当前分页状态 */
  paginationState: {
    page: number;
    page_size: number;
  };
  /** 分页组件配置 */
  paginationProps: PaginationProps;
  /** 重置分页状态 */
  resetPagination: () => void;
}

const usePagination = (
  options: UsePaginationOptions = {}
): PaginationResult => {
  const {
    total = 0,
    simple = false,
    initialCurrent = 1,
    initialPageSize = 10,
    onChange,
    onShowSizeChange,
    ...restPaginationProps
  } = options;

  // 分页状态管理
  const [current, setCurrent] = useState(initialCurrent);
  const [pageSize, setPageSize] = useState(initialPageSize);

  // 处理分页变化
  const handleChange = (page: number, newPageSize: number) => {
    setCurrent(page);
    if (pageSize !== newPageSize) {
      setPageSize(newPageSize);
    }
    onChange?.(page, newPageSize); // 调用外部传入的回调
  };

  // 处理页面尺寸变化
  const handleSizeChange = (_: number, newPageSize: number) => {
    setCurrent(1); // 切换页容量时自动回到第一页
    setPageSize(newPageSize);
    onShowSizeChange?.(1, newPageSize); // 调用外部传入的回调
  };

  // 重置分页状态
  const resetPagination = () => {
    setCurrent(1);
    setPageSize(20);
  };

  // 生成分页组件的 props
  const paginationProps: PaginationProps = {
    current,
    pageSize,
    total,
    simple,
    onChange: handleChange,
    onShowSizeChange: handleSizeChange,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total) => `共 ${total} 条`,
    ...restPaginationProps,
  };

  return {
    paginationState: {
      page: current,
      page_size: pageSize,
    },
    paginationProps,
    resetPagination,
  };
};

export default usePagination;