import React from "react";

export const ErrorSvg = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68" fill="none">
      <path
        d="M43.7407 38.4815C43.3878 38.4807 43.0382 38.5497 42.712 38.6845C42.3858 38.8192 42.0894 39.017 41.8398 39.2666C41.5902 39.5161 41.3924 39.8125 41.2577 40.1387C41.123 40.4649 41.054 40.8145 41.0547 41.1675V45.2719C41.0547 46.7598 42.2529 47.9579 43.7407 47.9579C45.2282 47.9579 46.4267 46.7598 46.4267 45.2719V41.1675C46.4235 40.4561 46.1395 39.7747 45.6364 39.2717C45.1334 38.7687 44.4521 38.4847 43.7407 38.4815ZM43.7407 49.2802C42.2529 49.2802 41.0547 50.5198 41.0547 52.0628C41.0547 53.6053 42.2529 54.845 43.7407 54.845C45.2282 54.845 46.4267 53.6053 46.4267 52.0628C46.4128 50.5198 45.2143 49.2802 43.7407 49.2802Z"
        fill="#ABC0ED"
      />
      <path
        d="M58.0121 0.0117188H9.77533C6.73131 0.0117188 4.26562 2.47706 4.26562 5.52108V62.1603C4.26562 65.2043 6.73131 67.67 9.77533 67.67H58.0121C61.0562 67.67 63.5218 65.2043 63.5218 62.1603V5.52108C63.5218 2.47706 61.0562 0.0117188 58.0121 0.0117188ZM14.4581 11.4167H45.2849C46.7724 11.4167 47.9709 12.6148 47.9709 14.1027C47.9709 15.5902 46.7724 16.7887 45.2849 16.7887H14.4581C14.1052 16.7894 13.7556 16.7204 13.4294 16.5857C13.1032 16.4509 12.8068 16.2531 12.5573 16.0036C12.3077 15.754 12.1099 15.4576 11.9751 15.1314C11.8404 14.8052 11.7714 14.4556 11.7721 14.1027C11.7721 12.6148 12.9706 11.4167 14.4581 11.4167ZM11.7725 24.7637C11.7725 23.2762 12.971 22.0777 14.4585 22.0777H29.8717C31.3592 22.0777 32.5577 23.2762 32.5577 24.7637C32.5577 26.2512 31.3592 27.4497 29.8717 27.4497H14.4581C14.1052 27.4504 13.7556 27.3815 13.4294 27.2467C13.1032 27.112 12.8068 26.9142 12.5573 26.6646C12.3077 26.415 12.1099 26.1187 11.9751 25.7925C11.8404 25.4662 11.7714 25.1166 11.7721 24.7637H11.7725ZM57.9156 56.8849C57.4474 57.6975 56.6484 58.1796 55.7668 58.1796H31.7176C30.8363 58.1796 30.0373 57.6975 29.5688 56.8849C28.9768 55.8656 28.9908 54.4607 29.5688 53.4414L41.5939 32.6425C42.0621 31.8299 42.8611 31.3478 43.7427 31.3478C44.624 31.3478 45.423 31.8299 45.8915 32.6425L57.9159 53.455C58.4946 54.4607 58.4946 55.8656 57.9159 56.8849H57.9156Z"
        fill="url(#paint0_linear_29685_86796)"
      />
      <defs>
        <linearGradient id="paint0_linear_29685_86796" x1="4.37337" y1="34.0534" x2="61.6821" y2="23.9063" gradientUnits="userSpaceOnUse">
          <stop stopColor="#8CACE9" />
          <stop offset="1" stopColor="#ADC2ED" />
        </linearGradient>
      </defs>
    </svg>
  );
};
