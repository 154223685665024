export const NulldataSvg = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="106" height="60" viewBox="0 0 106 60" fill="none">
      <g clipPath="url(#clip0_29228_114408)">
        <path d="M95.8365 11.9053H56.5898V59.1486H95.8365V11.9053Z" fill="url(#paint0_linear_29228_114408)" />
        <path d="M57.3544 11.9238H8.61914V58.7357L57.3544 59.1672V11.9238Z" fill="url(#paint1_linear_29228_114408)" />
        <path d="M104.397 0.87793L95.8068 11.924H56.6602L65.8636 0.87793H104.397Z" fill="url(#paint2_linear_29228_114408)" />
        <path d="M39.1717 1.1416L45.8777 11.9244H8.48911L0.115234 1.1416H39.1717Z" fill="url(#paint3_linear_29228_114408)" />
        <rect x="22.8965" y="23.7197" width="16.4961" height="6.30109" rx="3.15054" fill="#8BABE8" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_29228_114408" x1="56.6612" y1="35.6753" x2="94.734" y2="29.2812" gradientUnits="userSpaceOnUse">
          <stop stopColor="#8CACE9" />
          <stop offset="1" stopColor="#ADC2ED" />
        </linearGradient>
        <linearGradient id="paint1_linear_29228_114408" x1="57.5308" y1="41.3293" x2="8.81496" y2="39.5894" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9DBAF5" />
          <stop offset="1" stopColor="#D1E0F8" />
        </linearGradient>
        <linearGradient id="paint2_linear_29228_114408" x1="80.5207" y1="-1.00379" x2="80.2863" y2="12.1185" gradientUnits="userSpaceOnUse">
          <stop stopColor="#A7C1F6" />
          <stop offset="0.8947" stopColor="#8FA9D9" />
        </linearGradient>
        <linearGradient id="paint3_linear_29228_114408" x1="22.9888" y1="-0.695278" x2="22.7558" y2="12.1142" gradientUnits="userSpaceOnUse">
          <stop stopColor="#A7C1F6" />
          <stop offset="0.8947" stopColor="#BECEEE" />
        </linearGradient>
        <clipPath id="clip0_29228_114408">
          <rect width="105.261" height="58.2851" fill="white" transform="translate(0.115234 0.87793)" />
        </clipPath>
      </defs>
    </svg>
  );
};
