import { Tree } from "antd";
import type { TreeNodeProps } from "antd";

interface CustomTreeProps {
  treeData: any[];
  defalutcheckedKey: React.Key[],
  onChangeCheck?: (newValue?: any) => void;
  fieldNames?:{
    title: string,
    key: string,
    children: string,
  }
}

const CustomTreeComponent = (props: CustomTreeProps) => {
  const { treeData, defalutcheckedKey, fieldNames, onChangeCheck } = props
  const data = Array.isArray(treeData) ? treeData: [treeData]
  /**
   * @checkChildren 选中或取消选中所有子节点
   * @param node 选中节点数据
   * @param newCheckedKeys 选中数据列表
   * @param checked 是否选中
   */
  const checkParentForUncheck = (
    node: { parentId: number; parentCode: string },
    newCheckedKeys: { has: (arg0: any) => any; delete: (arg0: any) => void },
  ) => {
    if (node.parentId || node.parentCode) {
      const parentNode = findNodeByKey(data, node.parentId || node.parentCode);
      if (parentNode) {
        const allChildrenUnchecked = parentNode.children.every(
          (child: { key: number; code: string }) => !newCheckedKeys.has(child.key || child.code),
        );
        if (allChildrenUnchecked) {
          newCheckedKeys.delete(parentNode.key || parentNode.code);
          checkParentForUncheck(parentNode, newCheckedKeys); // 递归向上取消父节点
        }
      }
    }
  };
  /**
   * @checkParent 查找节点
   * @param node node列表
   * @param key 要查找的key
   */
  const findNodeByKey = (nodes: any[], key: number | string) => {
    for (const node of nodes) {
      if ((node.key || node.code) === key) {
        return node;
      }
      if (node.children) {
        const foundNode: any = findNodeByKey(node.children, key);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return null;
  };
  const checkChildren = (node: { children: any[] }, newCheckedKeys: { add: (arg0: any) => void; delete: (arg0: any) => void }, checked: boolean) => {
    if (node.children) {
      node.children.forEach((child: any) => {
        if (checked) {
          newCheckedKeys.add(child.key || child.code);
        } else {
          newCheckedKeys.delete(child.key || child.code);
        }
        checkChildren(child, newCheckedKeys, checked);
      });
    }
  };
  /**
   * @checkParent 选中父节点
   * @param node 选中节点数据
   * @param newCheckedKeys 选中数据列表
   */
  const checkParent = (node: { parentId: number; parentCode: any }, newCheckedKeys: { add: (arg0: any) => void }) => {
    if (node?.parentId || node?.parentCode) {
      newCheckedKeys.add(node.parentId || node.parentCode);
      const parentNode = findNodeByKey([data], node.parentId || node.parentCode);
      checkParent(parentNode, newCheckedKeys); // 递归向上选中父节点
    }
  };
  const handleCheck = (
    checkedKeys: React.Key[],
    checked: boolean,
    node: TreeNodeProps["data"],
  ) => {
    // 将所有的 checkedKeys 整合为一个数组
    const newCheckedKeys: any = new Set(checkedKeys);
    console.log(newCheckedKeys, "newCheckedKeysnewCheckedKeys");
    if (checked) {
      // 选中子节点时，选中父节点
      checkParent(node, newCheckedKeys);
      // 选中父节点时，选中所有子节点
      checkChildren(node, newCheckedKeys, true);
    } else {
      // 取消选中时，取消所有子节点
      checkChildren(node, newCheckedKeys, false);
      // 判断是否要取消父节点
      checkParentForUncheck(node, newCheckedKeys);
    }
    const res = Array.from(newCheckedKeys);
    onChangeCheck?.(res)
  };
  return (
    <div>
      <Tree
        treeData={data}
        checkable
        checkStrictly={true}
        checkedKeys={defalutcheckedKey}
        // defaultCheckedKeys={selectKeys[selectTabKey][id]}
        fieldNames={fieldNames ? fieldNames : {}}
        defaultExpandAll
        onCheck={(checkedKeys: React.Key[] | { checked: React.Key[] }, { node, checked }) => {
          if (Array.isArray(checkedKeys)) {
            handleCheck(checkedKeys, checked, node);
          } else {
            handleCheck(checkedKeys.checked, checked, node);
          }
        }}
      />
    </div>
  );
};

export default CustomTreeComponent;
