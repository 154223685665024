import React, { useRef, useEffect, useState, useMemo } from "react";
import GoogleMap from "google-map-react";
// import GoogleMap from "google-maps-react-markers";
import supercluster from "supercluster";
import { Tooltip, Divider, Button, message, Spin } from "antd";
import { StatusComponent } from "common";
import SearchComponent from "./searchComponent";
import { getMapList, getStationList, getStationDetail } from "../services/index";
import { PlusOutlined, MinusOutlined, FullscreenOutlined, FullscreenExitOutlined } from "@ant-design/icons";
import { getCurrentLocation, isExist } from "common/utils";
import _, { divide, set, toNumber } from "lodash";
import MarkSvg from "common/svg/markSvg";
import MarkMuliteSvg from "common/svg/markMuliteSvg";
import LocationSvg from "common/svg/locationSvg";
import MarkBoxSvg from "common/svg/markBoxSvg";
import CloseSvg from "common/svg/closeSvg";
import PowerSvg from "common/svg/powerSvg";
import "./index.css";
import { PhotovoltaicSvg, StorageNewSvg } from "../../../svgs/planExort/deviceViewSvg";
import { composeInitialProps, useTranslation } from "react-i18next";
import AMapLoader from "@amap/amap-jsapi-loader";
import { GAODE_KEY, GAODE_SAFETY_KEY } from "common/config";
import { createRoot } from "react-dom/client";
import { JSX } from "react/jsx-runtime";
import plantbg from "../../../assets/planImgs/plantbg.png";
import plantDarkbg from "../../../assets/planImgs/plantDarkbg.png";
import { formatDateNew } from "common/utils/DateUtils";
// import { pending } from "./config";
import { service, cancelRequest } from "common";

interface clustersType {
  type: string;
  id?: number;
  geometry?: {
    type: string;
    status?: number | string;
    coordinates: number[];
  };
  properties: {
    cluster: boolean;
    id: number;
    parentId?: number;
    num?: number;
    cluster_id?: number;
    point_count?: number;
    point_count_abbreviated?: number;
  };
  lat?: number;
  lng?: number;
  stationCount: number;
  stationMapCOList?: any;
  parentId?: number;
}
interface tooltipDataType {
  visible: {
    [key: number]: boolean;
  };
  data: object;
  multiple?: boolean;
}
interface boundsType {
  ne: { lat: number; lng: number };
  nw: { lat: number; lng: number };
  se: { lat: number; lng: number };
  sw: { lat: number; lng: number };
}
interface centerType {
  lat: number;
  lng: number;
}
interface mapOptionsType {
  bounds: boundsType;
  center: centerType;
  zoom: number;
}
interface locationsType {
  id: number;
  collectState: boolean;
  lat: number;
  lng: number;
  name: string;
  status: number;
  statusNamae?: string;
}
// let globalData: any = [];
let markers: { id: number; marker: any }[] = []; // 创建一个数组来保存所有 Marker
const MapComponent = (props: { positionId?: string; statusTabQueryKeys: any }) => {
  // const isCNFlag = localStorage.getItem("iscn") ? (localStorage.getItem("iscn") === "true" ? true : false) : process.env.REACT_APP_ISCN === ""; // 判断是否是国内环境 （国内环境高德，国外google）
  const { t } = useTranslation();
  const { positionId, statusTabQueryKeys } = props;
  const [isCNFlag, setIsCnFlag] = useState<boolean>(false);
  const [mapOptions, setMapOptions] = useState<mapOptionsType>({
    bounds: {
      ne: { lat: 0, lng: 0 },
      nw: { lat: 0, lng: 0 },
      se: { lat: 0, lng: 0 },
      sw: { lat: 0, lng: 0 },
    },
    center: { lat: 30.523801, lng: 104.098067 }, // 初始地图中心点
    // center: { lat: 0, lng: 0 },
    zoom: 3, // 初始地图缩放级别
  }); // 地图option对象
  const [googleTheme, setGoogleThme] = useState<any>([]);
  const [isFull, setIsFull] = useState<boolean>(false); // 是否全屏
  const [isFinish, setIsFinish] = useState<boolean>(false); // 是否请求结束
  const [stationList, setStationList] = useState<any[]>([]);
  const mapBoxRef = useRef<any>(null); // 地图box实例
  const mapRef = useRef<any>(); // 地图对象实例
  const mapGaodeRef = useRef<any>(); // 高德地图实例
  const gaodeMarkerRef = useRef<any>(null); // 高德地图标记实例
  const infoWindow = useRef<any>(null); // 高德地图弹窗实例
  const superClusterRef = useRef<any>(null); // 聚合实例
  const [currentClick, setCurrentClick] = useState<number | string>(""); // 高德地图当前点击标记
  const [clusters, setClusters] = useState<clustersType[]>([]); // 聚合后的标点数据
  const [locations, setLocations] = useState<locationsType[]>([]); // 地理位置数组
  const [locationsAll, setLocationsAll] = useState<locationsType[]>([]); // 地理位置数组（保存一份全部数据）
  const [opacity, setOpacity] = useState(1); // 做标点特殊处理的
  const [loading, setLoading] = useState<boolean>(false); // loading加载
  const [imageUrl, setImageUrl] = useState(null); // 图片url
  // const [currentState, setCurrentState] = useState(-1); // 当前用户所选左上角电站状态
  const currentState = useRef(-1); // 当前用户所选左上角电站状态
  const [dataType, setDataType] = useState(1);
  const globalData: any = useRef([]);
  const [googleApi, setGoogleApi] = useState<any>(null);
  const [searchValue, setSearchValue] = useState(-1);
  const cn = window?.env?.REACT_APP_ISCN === "1" || process.env.REACT_APP_ENV === "development";
  // 手动控制地图详情tooltip的显隐
  const [tooltipData, setTooltipData] = useState<tooltipDataType>({
    visible: {},
    data: {},
  });
  // tooltip弹窗底部数据
  const tooltipObj = [
    {
      label: `${t("panelCapacity")}（kWp）`,
      value: "capacity",
      icon: <PhotovoltaicSvg />,
    },
    {
      label: t("homeGenerationPower"),
      value: "generationPower",
    },
    {
      label: t("homeDailyGeneration"),
      value: "dailyGeneration",
    },
    {
      label: t("homeStorageCapacity"),
      value: "storageCapacity",
      icon: <StorageNewSvg />,
      isStorage: true,
    },
    {
      label: t("dumpEnergy"),
      value: "availCapacity",
      isStorage: true,
    },
    {
      label: t("homeInstalled"),
      value: "gridConnectedTime",
    },
  ];
  /**
   * 初始化
   */
  useEffect(() => {
    setLoading(true);
    setIsCnFlag(cn);
    // 强制延迟加载
    setTimeout(() => {
      if (cn) {
        initGaodeMap();
      } else {
        initGooleMap();
      }
      // 拉取下拉菜单数据
      getStationListFn(-1);
      const currentTheme: "dark" | "light" = (window?.localStorage.getItem("currentTheme") as "dark" | "light") || "dark";
      setImageUrl(currentTheme === "light" ? plantbg : plantDarkbg);
      window.addEventListener("localStorageChange", (e: any) => {
        setImageUrl(e.detail.value === "light" ? plantbg : plantDarkbg);
      });
    }, 1000);
  }, []);
  /**
   * 创建 supercluster 聚合器
   */
  const setLocationFn = (name?: number) => {
    const val = name === 0 ? name : name || currentState.current; // 因0为假值 当值为0时 会一直是后面的值
    superClusterRef.current = new supercluster({
      radius: 220, // 聚合半径
      maxZoom: 16, // 最大聚合缩放级别
    });
    const res = locationsAll;
    // if (val === -1) {
    //   res = locationsAll;
    // } else {
    //   const arr = locationsAll.filter((item: locationsType) => {
    //     if (val === 0) return item.status === val;
    //     // if (currentState !== 0) return item.status === currentState;
    //     if (val !== 0 && val !== 4) return item.status === val;
    //     if (val === 4) return item.collectState === true;
    //   });
    //   res = arr;
    // }
    superClusterRef.current.load(
      res.map((loc: locationsType) => ({
        type: "Feature",
        properties: { cluster: false, id: loc.id },
        geometry: { type: "Point", coordinates: [loc.lng, loc.lat], status: loc.status },
      })),
    );
  };
  useEffect(() => {
    if (dataType === 1) return;
    setLocationFn();
  }, [locations]);

  /**
   * 更新聚合点
   * @update 更新数据
   * @fixClusters 重新聚合 将重复经纬度坐标注入新类型
   */
  const fixClusters = (data: any[]) => {
    // 存储处理后的结果
    const newArray: any = [];
    const coordMap = new Map();

    // 遍历数据
    data.forEach((item) => {
      const coords = item.geometry.coordinates.toString(); // 将 coordinates 转换为字符串以便比较

      // 如果 point_count 存在，直接保留该对象
      if (item.properties.point_count) {
        newArray.push(item);
      } else {
        // 如果 coordinates 已经存在，表示找到了重复的坐标
        if (coordMap.has(coords)) {
          // 找到已经存在的索引并更新它的类型为 'a'，并更新 num 字段
          const existingIndex = coordMap.get(coords);
          let num = newArray[existingIndex].properties.num;
          newArray[existingIndex].geometry.type = "FeaturePoint"; // 修改类型为 'a'
          newArray[existingIndex].properties.num = num ? (num += 1) : 2; // 更新 num 字段
        } else {
          // 如果 coordinates 没有重复，存储该项并设置 num 为 1
          newArray.push({
            ...item, // 保留原始数据
            geometry: {
              ...item.geometry,
              type: "Point", // 修改类型为 'a'
            },
            properties: {
              ...item.properties,
            },
          });
          // 将坐标添加到 Map 中，记录当前索引
          coordMap.set(coords, newArray.length - 1);
        }
      }
    });
    return newArray;
  };
  const update = (locations?: any) => {
    if (dataType !== 1) {
      const { zoom, bounds } = mapOptions;
      const clusterBounds = [bounds.sw.lng, bounds.sw.lat, bounds.ne.lng, bounds.ne.lat];
      const clustersCurrent = superClusterRef.current.getClusters(clusterBounds, zoom);
      // 用于存储处理后的结果
      const res: clustersType[] = fixClusters(clustersCurrent);
      setClusters(res);
      if (isCNFlag) getGaodeMarker(res);
    } else {
      if (isCNFlag) {
        getGaodeMarker(locations, dataType);
      }
    }
  };
  useEffect(() => {
    update(locations);
  }, [mapOptions.zoom, JSON.stringify(mapOptions.bounds), currentClick, locations]);

  /**
   * 监听列表页点击定位后触发
   */
  useEffect(() => {
    if (loading) {
      message.error(t("homeNoLoading"));
      return;
    }
    const id = positionId?.split("&&")[0];
    console.log(id, "32432432");
    if (id) {
      if (+id !== 0) {
        handleReset();
        getMapList({ stationId: +id }).then((result: any) => {
          const res = result.data.dataList[0];
          if (!res.lat || !res.lng) {
            message.open({
              type: "error",
              content: t("homeNoLocation"),
            });
            return;
          }
          const center = {
            lat: res.lat,
            lng: res.lng,
          };

          if (isCNFlag) {
            setCurrentClick(+id);
            mapGaodeRef.current.setZoom(17, true);
            mapGaodeRef.current.setCenter([center.lng, center.lat], true);
          } else {
            setMapOptions({
              ...mapOptions,
              zoom: 20,
              center,
            });
          }
          setTimeout(() => {
            fixAboutTooltip({
              type: "Feature",
              properties: {
                cluster: false,
                id: +id,
              },
            });
          }, 500);
        });
      }
    }
  }, [positionId]);
  /** 
    监听列表状态改变
  */
  useEffect(() => {
    console.log(statusTabQueryKeys, statusTabQueryKeys);
    let value = -1;
    if (isExist(statusTabQueryKeys.runState)) {
      value = statusTabQueryKeys.runState;
    } else if (isExist(statusTabQueryKeys.stationState)) {
      value = 5;
    } else if (isExist(statusTabQueryKeys.isCollected)) {
      value = 4;
    } else {
      value = -1;
    }
    // console.log("value", value);
    // const status = !statusTabQueryKeys.runState && statusTabQueryKeys.runState !== 0 ? -1 : statusTabQueryKeys.runState;
    setSearchValue(value);
    handleChange(value);
  }, [statusTabQueryKeys]);
  /**
   * 监听左上方筛选状态后触发
   */
  useEffect(() => {
    if (isCNFlag) {
      if (!mapGaodeRef.current) return;
      const center = [mapGaodeRef.current.getCenter().lng, mapGaodeRef.current.getCenter().lat];
      getInit(
        {
          lat: center[1],
          lng: center[0],
        },
        bounds(),
        mapGaodeRef.current.getZoom(),
      );
    } else {
      getInit(mapOptions.center, mapOptions.bounds, mapOptions.zoom);
    }
  }, [currentState.current]);
  /**
   * @handleReset
   * 重置一下tooltip弹框
   */
  const handleReset = () => {
    if (isCNFlag) {
      // 这里使用官方api关闭窗体 但是dom没有删除？ 所以手动删除dom防止dom过多影响性能
      mapGaodeRef.current && mapGaodeRef.current.clearInfoWindow();
      const dom = document.getElementsByClassName("amap-info") as any;
      for (let i = 0; i < dom.length; i++) {
        dom[i].remove();
      }
    }
    setTooltipData({
      visible: {},
      data: {},
    });
  };

  /**
   * @getStationListFn 获取电站列表数据
   * @state 当前所选状态 -1 全部 1：正常 2：故障 0：离线 4：关注
   * @name 可选输入电站名称
   */
  const getStationListFn = _.debounce((state: number, name?: string) => {
    const params: {
      pageIndex: number;
      pageSize: number;
      stationQuery: string;
      runState?: number;
      stationState?: number;
      isCollected?: number;
    } = {
      pageIndex: 1,
      pageSize: 200, // 限制默认200条数据
      stationQuery: name || "",
    };
    if (state !== -1) {
      if ([1, 2, 0].includes(state)) params.runState = state;
      if (state === 4) params.isCollected = 0;
      if (state === 5) params.stationState = 1;
    }
    getStationList(params).then((res: any) => {
      if (!res.data) return;
      console.log(res.data, "32432");
      setStationList(res.data.data);
    });
  }, 500);

  /**
   * @getInit 获取数据
   */
  const normalizeCoord = (lat: any, lng: any) => {
    try {
      const newP = new window.google.maps.LatLng(lat, lng, false);
      return {
        lat: newP.lat(),
        lng: newP.lng(),
      };
    } catch (error) {
      return null;
    }
  };
  const normalizeCoord360 = (coord: any) => (coord / 360 > 1 ? coord % 360 : coord / -360 <= -1 ? coord % -360 : coord);
  const getInit = async (center: centerType, bounds: boundsType, zoom: number) => {
    // if (isDrag) return;
    const params: any = {};
    console.log(currentState.current, "currentState");
    if (currentState.current !== -1) {
      if ([1, 2, 0].includes(currentState.current)) params.runState = currentState.current;
      if (currentState.current === 4) params.isCollected = 0;
      if (currentState.current === 5) params.stationState = 1;
    }
    if (isCNFlag) {
      params.zoom = zoom;
      params.center = {
        lat: center.lat,
        lng: center.lng,
      };
      params.bounds = {
        ne: {
          lat: bounds.ne.lat,
          lng: bounds.ne.lng,
        },
        nw: {
          lat: bounds.nw.lat,
          lng: bounds.nw.lng,
        },
        se: {
          lat: bounds.se.lat,
          lng: bounds.se.lng,
        },
        sw: {
          lat: bounds.sw.lat,
          lng: bounds.sw.lng,
        },
      };
    } else {
      params.zoom = zoom;
      params.center = normalizeCoord(center.lat, center.lng) || {
        lat: center.lat,
        lng: center.lng,
      };
      params.bounds = {
        ne: normalizeCoord(bounds.ne.lat, bounds.ne.lng) || {
          lat: normalizeCoord360(bounds.ne.lat),
          lng: normalizeCoord360(bounds.ne.lng),
        },
        nw: normalizeCoord(bounds.nw.lat, bounds.nw.lng) || {
          lat: normalizeCoord360(bounds.nw.lat),
          lng: normalizeCoord360(bounds.nw.lng),
        },
        se: normalizeCoord(bounds.se.lat, bounds.se.lng) || {
          lat: normalizeCoord360(bounds.se.lat),
          lng: normalizeCoord360(bounds.se.lng),
        },
        sw: normalizeCoord(bounds.sw.lat, bounds.sw.lng) || {
          lat: normalizeCoord360(bounds.sw.lat),
          lng: normalizeCoord360(bounds.sw.lng),
        },
      };
    }
    try {
      const res: any = await getMapList(params);
      if (!res.data) return;
      globalData.current = res.data.dataList;
      await setLocations(res.data.dataList || []);
      await setLocationsAll(res.data.dataList || []);
      await setMapOptions({ center, zoom, bounds });
      await setDataType(res.data.dataType || 1);
    } catch (error) {
      console.log(error);
    }
  };
  /**
   * @initGoogleMap 谷歌初始化
   */
  const initGooleMap = () => {
    // 拉取用户当前坐标
    getCurrentLocation().then((res) => {
      console.log(res, "res");
      setLoading(false);
      setTimeout(() => {
        setMapOptions({
          bounds: mapOptions.bounds,
          center: { lat: res.lat, lng: res.lng },
          zoom: res.isReject ? 4 : mapOptions.zoom,
        });
        setIsFinish(true);
        // const currentTheme: "dark" | "light" = (window?.localStorage.getItem("currentTheme") as "dark" | "light") || "dark";
      }, 500);
    });
  };
  /**
   * @initGaodeMap 高德初始化
   */
  function gaodeDragStart(e: any) {
    console.log(isFinish, "isFinish");
    console.log(currentState.current, "32432432");
    if (e) {
      setCurrentClick("");
      handleReset();
    }
  }
  const initGaodeMap = async () => {
    // @ts-ignore
    window._AMapSecurityConfig = {
      securityJsCode: GAODE_SAFETY_KEY,
    };
    AMapLoader.load({
      key: GAODE_KEY,
      // version: "2.0",
      plugins: ["AMap.Marker", "AMap.Geocoder", "AMap.PlaceSearch"],
    })
      .then(async (AMap) => {
        console.log(121212121);
        setLoading(false);
        // mapInstance = AMap;
        const center: { lat: number; lng: number; isReject: boolean } = (await getCurrentLocation()) || { lat: 0, lng: 0, isReject: false };
        mapGaodeRef.current = new AMap.Map("containerModal", {
          mapStyle: "amap://styles/normal",
          zooms: [4, 17], // 设置缩放级别范围，最小缩放级别3，最大缩放级别18
          zoom: center.isReject ? 4 : mapOptions.zoom,
          center: [center.lng, center.lat],
        });
        setIsFinish(true);
        await getGaodeInfo();
        mapGaodeRef.current?.on("dragstart", gaodeDragStart);
        mapGaodeRef.current?.on("moveend", () => {
          getGaodeInfo();
        });
        // await getGaodeInfo();
        // drageStart可以通过e判断是手动行为还是自动行为
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  /**
   * @getGaodeInfo 高德获取信息
   */
  const bounds = () => {
    const bounds = mapGaodeRef.current.getBounds();
    let sw = bounds.getSouthWest();
    let nw = bounds.getNorthWest();
    let se = bounds.getSouthEast();
    let ne = bounds.getNorthEast();
    sw = new AMap.LngLat(sw.lng, sw.lat, false);
    nw = new AMap.LngLat(nw.lng, nw.lat, false);
    se = new AMap.LngLat(se.lng, se.lat, false);
    ne = new AMap.LngLat(ne.lng, ne.lat, false);
    console.log(sw, "32432432");
    return {
      ne: { lat: ne.lat || 0, lng: ne.lng || 0 },
      nw: { lat: nw.lat || 0, lng: nw.lng || 0 },
      se: { lat: se.lat || 0, lng: se.lng || 0 },
      sw: { lat: sw.lat || 0, lng: sw.lng || 0 },
    };
  };
  const getGaodeInfo = async () => {
    const center: centerType = mapGaodeRef.current.getCenter();
    const zoom = mapGaodeRef.current.getZoom();
    await getInit(
      {
        lat: center.lat,
        lng: center.lng,
      },
      bounds(),
      zoom,
    );
  };

  /**
   * @getGaodeMarker 高德创建标记
   * 因为高德地图无法直接使用reactDom 所以要用createRoot包裹
   */
  const createContent = (reactdom: string | number | boolean | JSX.Element | Iterable<React.ReactNode> | null | undefined) => {
    const dom = document.createElement("div");
    const content = createRoot(dom);
    content.render(reactdom);
    return dom;
  };
  const getGaodeMarker = async (data: clustersType[], type?: number) => {
    // 每次过去marker前 先清空所有marker 优化性能
    if (markers.length > 0) {
      markers.forEach((item: any) => {
        mapGaodeRef.current && mapGaodeRef.current.remove(item.marker);
      });
    }
    if (dataType === 1) {
      try {
        markers = [];
        data.forEach((item: clustersType) => {
          console.log(item, "item");
          const { lng, lat } = item;
          gaodeMarkerRef.current = new AMap.Marker({
            position: [lng, lat],
            content:
              type === 1 && item.stationCount !== 1
                ? createContent(<MarkerNew key={`cluster-${item.stationMapCOList[0]?.id}`} lat={lat || 0} lng={lng || 0} cluster={item} />)
                : createContent(DetailNew(item, lat || 0, lng || 0)),
          });
          if (item.stationMapCOList[0].id) {
            markers.push({ id: item.stationMapCOList[0]?.id, marker: gaodeMarkerRef.current });
          } // 将 Marker 和 ID 存储到数组中
          mapGaodeRef.current?.add(gaodeMarkerRef.current);
        });
      } catch (error) {
        console.log(error, "error");
      }
    } else {
      try {
        markers = [];
        data.forEach((item: clustersType) => {
          const { id, cluster_id, cluster: isCluster } = item.properties || item;
          const [lng, lat] = (item && item.geometry && item.geometry.coordinates) || [];
          gaodeMarkerRef.current = new AMap.Marker({
            position: [lng, lat],
            content: isCluster
              ? createContent(<Marker key={`cluster-${item.id}`} lat={lat} lng={lng} cluster={item} />)
              : createContent(Detail(item, lat, lng, item.geometry?.type === "Point" ? "singal" : "multiple")),
          });
          markers.push({ id, marker: gaodeMarkerRef.current }); // 将 Marker 和 ID 存储到数组中
          mapGaodeRef.current?.add(gaodeMarkerRef.current);
        });
      } catch (error) {
        console.log(error, "error");
      }
    }
  };
  /**
   * @fixSomeLocation 根据状态以及经纬度过滤电站
   * @param lat
   * @param lng
   * @returns
   */
  const fixSomeLocation = (lat: number | string, lng: number | string) => {
    const someLocation = locations.filter((item: locationsType) => {
      const prev = item.lat === lat && item.lng === lng;
      if (currentState.current === -1) return prev;
      if (currentState.current === 0) return prev && item.status === currentState.current;
      if (currentState.current === 4) return prev && item.collectState === true;
      if (currentState.current === 5) return prev && item.status === null;
      return prev && item.status === currentState.current;
    });
    return someLocation;
  };
  /**
   * @fixAboutTooltipGaode 对点击后tooltip的效果进行逻辑处理(高德)
   * @cluster 聚合数据
   * @data 聚合数据
   * @type 判断是否是多个电站
   */
  const fixAboutTooltipGaode = (data: any, clusterId: number, type?: string) => {
    const someLocation = data;
    const id = clusterId;
    console.log(markers, "markers");
    const foundMarker = markers.find((m: any) => m.id === id);
    console.log(foundMarker, "foundMarker");
    console.log(someLocation, "foundMarker");
    infoWindow.current = new AMap.InfoWindow({
      isCustom: true, // 使用自定义窗体
      // autoMove: true,
      content: createContent(
        <div className="w-[450px] max-w-[450px] bg-modulesBackground getTooltipContent rounded">{getTooltipContent(someLocation, type)}</div>,
      ),
      offset: new AMap.Pixel(16, -70),
      anchor: "bottom-center",
    });
    setTimeout(() => {
      infoWindow.current.open(mapGaodeRef.current, foundMarker?.marker.getPosition());
    }, 200);
  };
  /**
   * @fixAboutTooltip 对点击后tooltip的效果进行逻辑处理
   * @param cluster 聚合数据
   */

  const fixAboutTooltip = async (cluster: clustersType, data?: any) => {
    handleReset();
    console.log(dataType, "dataTypedataType");
    console.log(cluster, "clustercluster");
    let id = 0;
    if (cluster?.stationMapCOList) {
      id = cluster?.stationMapCOList[0]?.id;
    } else {
      id = cluster?.properties.id || cluster?.id || 1;
    }
    const currentId = globalData.current.filter((item: any) => item.id === id)[0];
    let currentLat: any[] = [];
    let first: any = {};
    if (currentId) {
      currentLat = globalData.current.filter((item: any) => item.lat === currentId.lat && item.lng === currentId.lng);
      if (isCNFlag) {
        const mapsId = markers.map((ele: any) => ele.id);
        first = currentLat.filter((eleC: any) => mapsId.indexOf(eleC.id) !== -1)[0];
      } else {
        // 这里是保证唯一性 确保相同经纬相同状态就一个电站的时候 就取自己id就好了
        if (data) {
          first = {
            id: data[0].id,
          };
        }
      }
    }
    const firstId = first?.id || currentLat[0]?.id || 0;
    // const id = cluster.properties.id || cluster.id || 1;
    const parentId = cluster.properties ? cluster.properties.parentId || "" : "";
    const res = await getStationDetail(id);
    if (!res.data) return;
    if (isCNFlag) {
      fixAboutTooltipGaode(res.data, parentId ? parentId : firstId ? firstId : id, "");
      return;
    }
    setTooltipData({
      visible: {
        [parentId ? parentId : firstId ? firstId : id]: true,
      },
      data: res.data || {},
    });
  };

  /**
   * @handleClickMarkDetail 当点击具体电站时触发
   * @param cluster 聚合数据
   */
  const handleClickMarkDetail = async (cluster: clustersType, type?: string) => {
    console.log(cluster, "clustercluster");
    if (type) {
      const [lng, lat] = dataType === 1 ? [cluster.lng, cluster.lat] : (cluster && cluster.geometry && cluster.geometry.coordinates) || [];
      const someLocation = fixSomeLocation(lat, lng);
      // 如果同一个经纬度有2个及以上电站
      if (someLocation.length >= 2) {
        const id = cluster.properties.id || cluster.id || 1;
        setTooltipData({
          multiple: true,
          visible: {
            [id]: true,
          },
          data: someLocation,
        });
      } else {
        console.log(cluster, "cluster");
        fixAboutTooltip(cluster, someLocation);
      }
    } else {
      fixAboutTooltip(cluster);
    }
  };

  /**
   * @getTooltipContent 点击电站详情dom
   * @param cluster 当前聚合数据
   * @returns
   */
  const getTooltipContent = (data: any, type?: string): React.ReactNode => {
    console.log(data, "data");
    if (Object.keys(data).length === 0) return "";
    if (type) {
      return (
        <div className="flex justify-between p-3 rounded">
          <ul className="list-none mt-6 p-0 w-full tooltipContent">
            {data.map((item: any) => {
              return (
                <li
                  key={item.id}
                  className="h-[36px] flex items-center cursor-pointer text-cMainTxt hover:bg-selectedColor p-1 border-b-[1px] border-[0px] border-solid border-dividingLine"
                  onClick={() => {
                    fixAboutTooltip({
                      type: "Feature",
                      properties: {
                        cluster: false,
                        id: item.id,
                        parentId: data[0].id, // 因为同坐标的数据都聚合了 所以我仅仅只有第一个的id 所以这里通用第一个的id作为tooltip窗体进行判断，仅改变里面内容
                      },
                    });
                  }}
                >
                  <span className="relative">
                    <span
                      className="absolute w-[6px] h-[6px] rounded-[50%] block -right-[2px] bottom-[4px]"
                      style={{
                        background: item.status === 1 ? "#1AA369 " : item.status === 0 ? "#6E7280" : item.status === 2 ? "#EF4444" : "#F9B233",
                      }}
                    ></span>
                    <PowerSvg />
                  </span>
                  <span className="ml-2">{item.name}</span>
                </li>
              );
            })}
          </ul>
          <div>
            <span
              className="cursor-pointer"
              onClick={() => {
                handleReset();
                setCurrentClick("");
              }}
            >
              <CloseSvg color="var(--color-textAssist)" />
            </span>
          </div>
        </div>
      );
    }
    return (
      <div className="bg-modulesBackground p-[20px] rounded">
        <div className="flex gap-4 items-start">
          <div className={`${data.imageUrl ? "w-[120px] h-[80px]" : ""}`}>
            <img src={data.imageUrl || imageUrl} alt="" className={`w-[120px] h-[80px] max-w-[120px] rounded-sm`} />
            {/* {data.imageUrl ? (
              <img src={data.imageUrl} alt="" className="w-[120px] h-[80px] max-w-[120px]" />
            ) : (
              <img src="" alt="" />
            )} */}
          </div>
          <div className="w-[70%]">
            <div className="flex items-center">
              <span className="flex items-center gap-2 flex-1 w-[70%]">
                <span
                  title={data.name}
                  className="max-w-[150px] text-cMainTxt font-semibold text-base overflow-hidden text-ellipsis whitespace-nowrap"
                >
                  {data.name || ""}
                </span>
                <span>
                  <StatusComponent
                    flag={data.status === 1 ? "success" : data.status === 0 ? "info" : data.status === 2 ? "error" : "inactivated"}
                    value={
                      data.status === 1
                        ? t("homeRunNormal")
                        : data.status === 0
                          ? t("homeRunOffline")
                          : data.status === 2
                            ? t("homeRunFault")
                            : t("homeRunActivations")
                    }
                  />
                </span>
              </span>
              <span
                className="flex items-center ml-2 cursor-pointer"
                onClick={() => {
                  handleReset();
                  setCurrentClick("");
                }}
              >
                <CloseSvg color="var(--color-textAssist)" />
              </span>
            </div>
            <div className="text-cMinorTxt mt-3 flex items-">
              <span className="inline align-text-top mr-1 mt-[2px]">
                <LocationSvg style={{ marginTop: "2px" }} />
              </span>
              <span className="ml-1">{data.address}</span>
            </div>
          </div>
        </div>
        <Divider style={{ margin: 0, marginTop: 16, marginBottom: 16 }} className=" border-dividingLine" />
        <div>
          {tooltipObj.map((item) => {
            return (
              <>
                {(item.isStorage && data.storageExist) || !item.isStorage ? (
                  <div className="mb-2 flex items-center text-cMainTxt" key={item.value}>
                    <span className="w-[24px] mr-2">{item.icon ? item.icon : null}</span>
                    <span className="block max-w-[260px] w-[180px] text-ellipsis  text-left ">{item.label} : </span>
                    <span className="ml-4">
                      {item.value === "gridConnectedTime"
                        ? formatDateNew(data[item.value], false)
                        : item.value === "generationPower"
                          ? data[item.value]
                            ? toNumber((data[item.value] / 1000).toFixed(2))
                            : data[item.value] === 0
                              ? 0
                              : "--"
                          : item.value === "dailyGeneration" && data[item.value] !== null
                            ? data[item.value] === 0
                              ? 0
                              : Number(data[item.value]?.toFixed(2))
                            : item.value === "availCapacity"
                              ? data[item.value]
                                ? `${data[item.value]}%`
                                : "--"
                              : data[item.value] || "--"}
                    </span>
                  </div>
                ) : null}
              </>
            );
          })}
        </div>
      </div>
    );
  };

  /**
   * @Marker 自定义的标记聚类组件
   */
  const Marker = ({ lat, lng, cluster }: { lat: number | string; lng: number | string; cluster: clustersType }) => (
    <div
      style={{
        position: "absolute",
        top: "100%",
        left: "50%",
        transform: "translate(-50%, -100%)",
        opacity: opacity,
      }}
      // className="marker"
      className="marker text-[#3e7ef8] font-bold text-sm rounded-[50%] bg-white border-4 border-[#3e7ef8] border-solid w-[36px] h-[36px] text-center"
      onClick={() => {
        // 获取集群内部下一等级缩放级别
        console.log(cluster, "cluster");
        console.log(superClusterRef.current.getChildren(cluster.id), "cluster");
        console.log(superClusterRef.current.getLeaves(cluster.id), "cluster");
        const firstChildren = superClusterRef.current.getLeaves(cluster.id)[0].geometry.coordinates;
        const lnglat = cluster?.geometry?.coordinates || firstChildren;
        const firstZoom = superClusterRef.current?.getClusterExpansionZoom(cluster.id);
        // 获取集群内部第一个子节点
        if (isCNFlag) {
          mapGaodeRef.current.setZoom(firstZoom, true);
          mapGaodeRef.current.setCenter(lnglat, true);
          return;
        }
        mapRef.current.map_.setCenter({
          lat: lnglat[1],
          lng: lnglat[0],
        });
        mapRef.current.map_.setZoom(firstZoom);
      }}
    >
      {/* <MarkBoxSvg className="marker" /> */}
      <span className="numberMarker block">{cluster?.properties?.point_count}</span>
    </div>
  );
  /**
   * @MarkerDetail 自定义的标记具体点组件
   */
  const MarkerDetail = ({ lat, lng, cluster, type }: { lat: number | string; lng: number | string; cluster: clustersType; type?: string }) => (
    <Tooltip
      placement="top"
      getPopupContainer={() => {
        const dom = document.getElementById("mapBox");
        return dom || document.body;
      }}
      title={() =>
        tooltipData.multiple
          ? getTooltipContent(tooltipData.data, "multiple")
          : tooltipData.visible[cluster.properties.id || 1]
            ? getTooltipContent(tooltipData.data)
            : null
      }
      trigger="click"
      open={tooltipData.visible[cluster.properties.id || 1]}
      color="var(--color-modulesBackground)"
      overlayStyle={{
        width: "450px",
        maxWidth: "450px",
        borderRadius: "4px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.10)",
      }}
    >
      {Detail(cluster, lat, lng, type)}
    </Tooltip>
  );
  const Detail = (cluster: clustersType, lat: number | string, lng: number | string, type: string) => {
    const data: clustersType = cluster; // 在这里因为前面用的createRoot包裹，所以直接访问cluster属性会导致ts类型推断错误，所以设定了一个变量进行接收；
    console.log(data, "2324");
    return (
      <div
        style={{
          opacity: opacity,
          position: "absolute",
          top: "100%",
          left: "50%",
          transform: `translate(-50%, ${tooltipData.visible[data.properties.id || 1] ? "-100%" : "-100%"})`,
        }}
        className="markerDetail cursor-pointer"
        onClick={() => {
          console.log(11111);
          if (isCNFlag) {
            setCurrentClick(data.properties.id);
            const someLocation = fixSomeLocation(lat, lng);
            if (type === "multiple") {
              fixAboutTooltipGaode(someLocation, data.properties.id, "multiple");
            } else {
              fixAboutTooltip(cluster);
            }
          } else {
            handleClickMarkDetail(cluster, "isClick");
          }
        }}
      >
        {type === "singal" ? (
          <>
            <span
              className={`${isCNFlag ? (data.properties.id === currentClick ? "" : "hidden") : tooltipData.visible[data.properties.id || 1] ? "" : "hidden"} markerDetail-${cluster.properties.id} absolute -left-[25px] markerChildren`}
            >
              <MarkBoxSvg />
            </span>
            <span className="relative z-50">
              <MarkSvg
                color={
                  data?.geometry?.status === 1
                    ? "#1AA369 "
                    : data?.geometry?.status === 0
                      ? "#6E7280"
                      : data?.geometry?.status === 2
                        ? "#EF4444"
                        : "#F9B233"
                }
              />
            </span>
          </>
        ) : (
          <span className="relative flex justify-center">
            <MarkMuliteSvg />
            <span className="numberMarker absolute text-[#347ef8] text-sm">{data.properties.num}</span>
          </span>
        )}
      </div>
    );
  };
  /**
   * @Marker 自定义的标记聚类组件
   */
  const MarkerNew = ({ lat, lng, cluster }: { lat: number | string; lng: number | string; cluster: clustersType }) => (
    // console.log(lat, "lat")
    // console.log(lng, "lnglnglng")
    <div
      style={{
        position: "absolute",
        top: "100%",
        left: "50%",
        transform: "translate(-50%, -100%)",
        opacity: opacity,
      }}
      // className="marker"
      className="marker  text-[#3e7ef8] font-bold text-sm rounded-[50%] bg-white border-4 border-[#3e7ef8] border-solid w-[36px] h-[36px] text-center"
      onClick={() => {
        // 获取集群内部第一个子节点
        const zoom = isCNFlag ? mapGaodeRef.current.getZoom() : mapRef.current.map_.getZoom();
        if (isCNFlag) {
          mapGaodeRef.current.setZoom(zoom + 1, true);
          mapGaodeRef.current.setCenter([cluster.lng, cluster.lat], true);
          return;
        }
        mapRef.current.map_.setCenter({
          lat: cluster.lat,
          lng: cluster.lng,
        });
        mapRef.current.map_.setZoom(zoom + 1);
      }}
    >
      {/* <MarkBoxSvg className="marker" /> */}
      <span className={`numberMarker block ${cluster?.stationCount >= 1000 ? "text-[12px]" : "text-[16px]"}`}>
        {formatAmountWithUnit(cluster?.stationCount)}
      </span>
    </div>
  );
  /**
   * @MarkerDetail 自定义的标记具体点组件
   */
  const MarkerDetailNew = ({ lat, lng, cluster }: { lat: number | string; lng: number | string; cluster: clustersType }) => (
    <Tooltip
      placement="top"
      getPopupContainer={() => {
        const dom = document.getElementById("mapBox");
        return dom || document.body;
      }}
      title={() =>
        tooltipData.multiple
          ? getTooltipContent(tooltipData.data)
          : tooltipData.visible[dataType === 1 ? cluster.stationMapCOList[0].id : cluster.properties.id || 1]
            ? getTooltipContent(tooltipData.data)
            : null
      }
      trigger="click"
      open={tooltipData.visible[dataType === 1 ? cluster.stationMapCOList[0].id : cluster.properties.id || 1]}
      color="var(--color-modulesBackground)"
      overlayStyle={{
        width: "450px",
        maxWidth: "450px",
        borderRadius: "4px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.10)",
      }}
    >
      {DetailNew(cluster, lat, lng)}
    </Tooltip>
  );
  const DetailNew = (cluster: clustersType, lat: number | string, lng: number | string) => {
    const data: clustersType = cluster; // 在这里因为前面用的createRoot包裹，所以直接访问cluster属性会导致ts类型推断错误，所以设定了一个变量进行接收；
    const { status, id } = data.stationMapCOList[0];
    const visibleId = `${data.lat}-${data.lng}`;
    const stationCount = data.stationCount;
    return (
      <div
        style={{
          opacity: opacity,
          position: "absolute",
          top: "100%",
          left: "50%",
          transform: `translate(-50%, ${tooltipData.visible[visibleId || 1] ? "-100%" : "-100%"})`,
        }}
        className="markerDetail cursor-pointer"
        onClick={() => {
          if (isCNFlag) {
            setCurrentClick(id);
            if (stationCount === 1) {
              fixAboutTooltip(cluster);
            } else {
              fixAboutTooltipGaode(cluster, data.stationMapCOList);
            }
          } else {
            handleClickMarkDetail(cluster, "isClick");
          }
        }}
      >
        {stationCount === 1 ? (
          <>
            <span
              className={`${isCNFlag ? (id === currentClick ? "" : "hidden") : tooltipData.visible[visibleId || 1] ? "" : "hidden"} markerDetail-${id} absolute -left-[25px] markerChildren`}
            >
              <MarkBoxSvg />
            </span>
            <span className="relative z-50">
              <MarkSvg color={status === 1 ? "#1AA369 " : status === 0 ? "#6E7280" : status === 2 ? "#EF4444" : "#F9B233"} />
            </span>
          </>
        ) : (
          <span className="relative flex justify-center">
            <MarkMuliteSvg />
            <span className="absolute top-[5px] text-[#347ef8] text-sm">{formatAmountWithUnit(stationCount)}</span>
          </span>
        )}
      </div>
    );
  };
  /**
   * @handleChange 当切换状态时触发
   * @name 状态
   */
  const handleChange = async (name: number) => {
    handleReset();
    currentState.current = name;
    // setCurrentState(name);

    getStationListFn(name);
  };
  /**
   * @handleSearch 当点击搜索时触发
   * @param searchValue
   *  @name 电站名
   *  @state 电站状态
   */
  const handleSearch = async (searchValue: { name: number; state: number }) => {
    handleReset();
    const parmas: {
      stationId: number;
      runState?: number;
      isCollected?: number;
      stationState?: number;
    } = {
      stationId: searchValue.name,
    };
    if (!searchValue.name) return;
    if (searchValue.state !== -1) {
      if ([1, 2, 0].includes(currentState.current)) parmas.runState = searchValue.state;
      if (searchValue.state === 4) parmas.isCollected = 0;
      if (searchValue.state === 5) parmas.stationState = 1;
    }
    const result: any = await getMapList(parmas);
    const res = result.data.dataList[0];
    const id = res.id || res.stationMapCOList[0].id;
    if (!res.lat || !res.lng) {
      // message.error(t("homeMapNoFound"));
      return;
    }
    const center = {
      lat: res.lat,
      lng: res.lng,
    };
    if (isCNFlag) {
      setCurrentClick(id);
      mapGaodeRef.current.setZoom(17, true);
      mapGaodeRef.current.setCenter([center.lng, center.lat], true);
      setTimeout(() => {
        fixAboutTooltip({
          type: "Feature",
          properties: {
            cluster: false,
            id: id,
          },
        });
      }, 500);
    } else {
      // 如果在视野范围的电站 则不用重新渲染可视区域
      const hasNode = clusters.filter((item) => item.properties.id === id);
      if (hasNode && hasNode.length === 0) {
        await setMapOptions({
          ...mapOptions,
          zoom: 17,
          center,
        });
      }
      setTimeout(() => {
        handleClickMarkDetail({
          type: "Feature",
          properties: {
            cluster: false,
            id: id,
          },
        });
      }, 500);
    }
  };

  /**
   * @handleClickControl 当点击control控件时触发
   * @type 类型
   */
  const handleClickControl = (type: "add" | "reduce" | "reset") => {
    let zoom = isCNFlag ? mapGaodeRef.current.getZoom() : mapRef.current.map_.getZoom();
    if ((type === "add" && zoom >= 17) || (type === "reduce" && zoom <= 4)) return;
    switch (type) {
      case "add":
        zoom += 1;
        break;
      case "reduce":
        zoom -= 1;
        break;
      default:
        zoom = 5;
    }
    if (isCNFlag) {
      const center = [mapGaodeRef.current.getCenter().lng, mapGaodeRef.current.getCenter().lat];
      mapGaodeRef.current.setZoom(zoom, true);
      mapGaodeRef.current.setCenter(center, true);
      getInit(
        {
          lat: center[1],
          lng: center[0],
        },
        bounds(),
        zoom,
      );
    } else {
      mapRef.current.map_.setZoom(zoom);
      setMapOptions({
        ...mapOptions,
        zoom,
      });
    }
  };
  const googleInterfaceCancel = () => {
    cancelRequest().removeSpecifyRequest("/energy-manage/web/station/listStationMap");
  };
  const formatAmountWithUnit = (amount: number) => {
    let formattedAmount;
    // 转换为数值类型
    amount = Number(amount);
    if (amount >= 10000) {
      // 金额大于等于1万时，转换为“万”为单位，保留两位小数
      formattedAmount = (amount / 10000).toFixed(1) + "W";
    } else if (amount >= 1000) {
      // 金额大于等于1千，小于1万时，显示为“千”
      formattedAmount = (amount / 1000).toFixed(0) + "K";
    } else {
      // 金额小于1千，直接显示原金额，保留两位小数
      formattedAmount = amount;
    }
    return formattedAmount;
  };
  return (
    <>
      {loading && (
        <div className="relative bg-allBackground  z-[1000] h-full w-full">
          <Spin className="absolute top-1/2 right-1/2"></Spin>
        </div>
      )}
      <div className="relative mapBoxNoFull" ref={mapBoxRef} id="mapBox">
        {isCNFlag ? (
          <div className="w-500 h-full relative" id="containerModal"></div>
        ) : (
          isFinish && (
            <GoogleMap
              ref={mapRef}
              bootstrapURLKeys={{
                key: "AIzaSyAMKP6ioiWYGgupPx6gs4PSNCyWyiOtwjM",
              }}
              yesIWantToUseGoogleMapApiInternals={true}
              defaultCenter={mapOptions.center}
              defaultZoom={mapOptions.zoom}
              center={mapOptions.center}
              debounced={true}
              zoom={mapOptions.zoom}
              disableDefaultUI={true}
              resetBoundsOnResize={true}
              options={{
                styles: googleTheme,
                maxZoom: 17,
                minZoom: 4,
                restriction: {
                  latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
                  strictBounds: true,
                },
              }}
              onGoogleApiLoaded={async (data: any) => {
                setGoogleApi(data.map);
              }}
              onZoomAnimationStart={() => {
                googleInterfaceCancel();
              }}
              onDrag={() => {
                googleInterfaceCancel();
                // setOpacity(0);
                setTooltipData({
                  visible: {},
                  data: {},
                });
              }}
              onDragEnd={() => {
                // setOpacity(1);
              }}
              onChange={async ({ center, zoom, bounds }: any) => {
                getInit(center, bounds, zoom);
                // _.debounce(getInit(center, bounds, zoom), 500);
                // await getInit();
              }}
            >
              {dataType === 1
                ? locations.map((item: any, number: number) => {
                    if (item.lat && item.lng) {
                      if (dataType === 1 && item.stationCount !== 1) {
                        return <MarkerNew key={`cluster-${number}`} lat={item.lat} lng={item.lng} cluster={item}></MarkerNew>;
                      }
                      return <MarkerDetailNew key={number} lat={item.lat} lng={item.lng} cluster={item} />;
                    }
                  })
                : clusters.map((item: clustersType) => {
                    const { id, cluster: isCluster } = item.properties;
                    const [lng, lat] = (item && item.geometry && item.geometry.coordinates) || [];
                    if (isCluster) {
                      return <Marker key={`cluster-${item.id}`} lat={lat} lng={lng} cluster={item}></Marker>;
                    }
                    console.log(item, "trtreter");
                    return (
                      <MarkerDetail key={id} lat={lat} lng={lng} cluster={item} type={item.geometry?.type === "Point" ? "singal" : "multiple"}>
                        {/* <div style={{ color: "blue" }}>地点标记</div> */}
                      </MarkerDetail>
                    );
                  })}
            </GoogleMap>
          )
        )}

        {/* 以下是右上角控制组件 */}
        {!loading && (
          <div className="control absolute right-1 top-6 flex">
            <div className="shadow-div bg-modulesBackground rounded flex gap-2 items-center mr-2 px-3 py-2 cursor-pointer">
              <PlusOutlined
                // let zoom = isCNFlag ? mapGaodeRef.current.getZoom() : mapRef.current.map_.getZoom();
                className={`${mapGaodeRef?.current?.getZoom() === 17 || mapRef?.current?.map_?.getZoom() === 17 ? "!cursor-not-allowed text-cDisableTxt" : ""}`}
                onClick={() => {
                  handleClickControl("add");
                }}
              />
              <MinusOutlined
                className={`${mapGaodeRef?.current?.getZoom() === 4 || mapRef?.current?.map_?.getZoom() === 4 ? "!cursor-not-allowed text-cDisableTxt" : ""}`}
                onClick={() => {
                  handleClickControl("reduce");
                }}
              />
            </div>
            <div
              className="shadow-div bg-modulesBackground rounded px-3 py-2 cursor-pointer mr-6"
              onClick={() => {
                handleChange(searchValue ?? -1);
                mapBoxRef.current.className = `relative ${isFull ? "mapBoxNoFull" : "mapBoxIsFull"}`;
                setIsFull(!isFull);
              }}
            >
              {isFull ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
            </div>
          </div>
        )}

        {/* 以下是左上角筛选框 */}
        {isFull ? (
          <div className="absolute left-6 top-6">
            <SearchComponent
              defaultValue={searchValue}
              stationList={stationList}
              getStationListFn={(state: number, name: string) => getStationListFn(state, name)}
              handleSearch={(searchValue: any) => handleSearch(searchValue)}
              handleChange={(name: number) => handleChange(name)}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default MapComponent;
