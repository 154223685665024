import React from "react";
import { Transfer, Tree, Checkbox, Collapse, List } from "antd";
import type { GetProp, TransferProps, TreeDataNode } from "antd";
import { mustArray } from "../../utils";
import { useTranslation } from "react-i18next";
import GlobalNoData from "../GlobalNoData";
import VirtualList from "rc-virtual-list";
type TransferItem = GetProp<TransferProps, "dataSource">[number];
interface TreeTransferType {
  status?: TransferProps["status"];
  dataSource: TreeDataNode[] | any[];
  flatSource?: any[];
  disabled?: boolean;
  isInstaller?: boolean;
  isStation?: boolean;
  collapseItem?: any;
  selectedKeys?: any[];
  searchPlaceholder?: string;
  searchValue?: string;
  showSearch?: boolean;
  isMore?: { more: boolean; pageIndex: number } | null;
  targetKeys: TransferProps["targetKeys"];
  onChange: TransferProps["onChange"];
  onSearch?: TransferProps["onSearch"];
}
const TreeTransfer: React.FC<TreeTransferType> = ({
  dataSource,
  flatSource,
  targetKeys = [],
  isInstaller,
  isStation,
  collapseItem,
  onSearch,
  showSearch,
  searchValue,
  isMore,
  searchPlaceholder,
  ...restProps
}) => {
  const transferDataSource: TransferItem = dataSource[0];
  const children = transferDataSource.children;
  const { t } = useTranslation();
  console.log(transferDataSource, "transferDataSource");
  console.log(targetKeys, "targetKeystargetKeys");
  console.log(children, "childrenchildren");
  // 如果传进来的是双层结构，则需要处理一下
  const flatten = (data: any) => {};
  flatten(dataSource);
  return (
    <Transfer
      {...restProps}
      // showSearch
      showSelectAll={false}
      showSearch={showSearch}
      locale={{
        searchPlaceholder: searchPlaceholder || t("sysOpenApiPleaseSelect"),
      }}
      targetKeys={targetKeys}
      dataSource={children}
      className="openapi-tree-transfer"
      render={(item) => item.label || item.title}
      onSearch={onSearch}
    >
      {({ direction, onItemSelect, onItemSelectAll, selectedKeys }) => {
        if (direction === "left") {
          const checkedKeys = [...selectedKeys, ...targetKeys];
          return (
            <List>
              {/* <div className="ml-2 mb-2">
                <Checkbox
                  style={{
                    color: "var(--color-textMain) !important",
                  }}
                  onChange={(val) => {
                    console.log(val, "12121");
                    const arr = children.map((item: any) => item.id);
                    console.log(arr, "wewqeqeqw");
                    // 这里必须用选择组选择否则会报错
                    onItemSelectAll(arr, val.target.checked ? true : false);
                  }}
                >
                  {transferDataSource.title}
                </Checkbox>
              </div> */}
              <VirtualList
                data={children}
                height={300}
                itemHeight={47}
                itemKey="id"
                className="ml-4"
                // onScroll={onScroll}
              >
                {(item: any) => (
                  <Checkbox
                    className="mb-2"
                    style={{
                      color: "var(--color-textMain) !important",
                    }}
                    key={item.key}
                    value={item.key}
                    onChange={(val) => {
                      console.log(val, "12121");
                      console.log(selectedKeys, "selectedKeys");
                      const value = val.target.value;
                      // 这里必须用选择组选择否则会报错
                      onItemSelectAll(
                        [value],
                        selectedKeys.includes(value) ? false : true
                      );
                    }}
                  >
                    {item.title}
                  </Checkbox>
                  //   <List.Item key={item.id}>
                  //     <div>{item.title}</div>
                  //   </List.Item>
                )}
              </VirtualList>
            </List>
          );
        }
      }}
    </Transfer>
  );
};
export default TreeTransfer;
