import { BoxConfig, extarPropsType } from "../getInput";
import React, { useEffect, useState, useRef } from "react";
import { Select, SelectProps, Spin } from "antd";
import { useDebounceFn } from "ahooks";
import request from "../../../utils/request";

interface Props extends extarPropsType {
  queryKey?: string;
  url?: string;
}
interface ResponseType<T = any> {
  code: number | null;
  msg: string | null;
  success: boolean;
  data: T;
  pageIndex: number;
  pageSize: number;
  total: number;
  totalPage: number;
}
interface Pagination {
  pageIndex: number;
  pageSize: number;
  totalPage: number;
}

export const SelectWithSearch = ({ inputOptions, extraOptions }: BoxConfig, { inputDefaultStyle }: Props) => {
  const extarStyles = inputOptions?.styles || {};
  const query = useRef("");
  const requestIndex = useRef(0);
  const pagination = useRef<Pagination>({
    pageIndex: 1,
    pageSize: 20,
    totalPage: 0
  });
  const dropdownElement = useRef<HTMLDivElement>();
  const { run: onSearch } = useDebounceFn(
    (str: string) => {
      if (open) {
        query.current = str;
        pagination.current.pageIndex = 1;
        pagination.current.totalPage = 0;
        loadMoreData();
      }
    },
    {
      wait: 300
    }
  );
  const [options, setOptions] = useState<SelectProps["options"]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { url, queryKey = "query", labelField = "label", valueField = "value" } = extraOptions || {};
  const fieldNames = { label: labelField, value: valueField };
  const loadMoreData = (type?: "scroll") => {
    if (!url) {
      return;
    }
    requestIndex.current++;
    const currentRequest = requestIndex.current;
    setLoading(true);
    const params = {
      pageIndex: pagination.current.pageIndex,
      pageSize: pagination.current.pageSize,
      [queryKey]: query.current.trim()
    };
    request
      .post<ResponseType>(url, params)
      .then((result) => {
        if (currentRequest !== requestIndex.current) {
          return;
        }
        const resultData = result?.data?.data || [];
        if (type === "scroll") {
          setOptions([...options!, ...resultData]);
        } else {
          if (dropdownElement.current) {
            dropdownElement.current.scrollTop = 0;
          }
          setOptions([...resultData]);
        }
        pagination.current.totalPage = result?.data?.totalPage || 0;
      })
      .finally(() => {
        if (currentRequest === requestIndex.current) {
          setLoading(false);
        }
      });
  };
  const onDropdownVisibleChange: SelectProps["onDropdownVisibleChange"] = (visible) => {
    setOpen(visible);
    if (visible) {
      query.current = "";
      pagination.current.pageIndex = 1;
      pagination.current.totalPage = 0;
      loadMoreData();
    }
  };
  const onPopupScroll: SelectProps["onPopupScroll"] = (e) => {
    dropdownElement.current = e.target as HTMLDivElement;
    const { scrollHeight, scrollTop, clientHeight } = e.target as HTMLDivElement;
    const { pageIndex, totalPage } = pagination.current;
    if (scrollTop + clientHeight >= scrollHeight && pageIndex < totalPage) {
      pagination.current.pageIndex++;
      loadMoreData("scroll");
    }
  };
  const dropdownRender: SelectProps["dropdownRender"] = (menu) => {
    return <Spin spinning={loading}>{menu}</Spin>;
  };
  return (
    <Select
      style={{
        minWidth: "250px",
        ...inputDefaultStyle,
        ...extarStyles
      }}
      options={options}
      fieldNames={fieldNames}
      showSearch
      onSearch={onSearch}
      onPopupScroll={onPopupScroll}
      open={open}
      filterOption={false}
      onDropdownVisibleChange={onDropdownVisibleChange}
      dropdownRender={dropdownRender}
      notFoundContent={<span></span>}
      {...inputOptions}
    />
  );
};
