import React, { useState } from "react";
import { Popover } from "antd";
import clsx from "clsx";
import { useMemoizedFn } from "ahooks";
import isFunction from "lodash/isFunction";
import { isExist } from "../../utils";
import { useGetColor } from "../../utils/hooks";
type dropPlacement =
  | "bottom"
  | "bottomLeft"
  | "bottomRight"
  | "top"
  | "topLeft"
  | "topRight";

export interface DataSourceType {
  id: string;
  label: string | React.ReactNode;
  val?: string;
  icon?: any;
  isCheck?: boolean;
  canSelect: boolean;
  children?: DataSourceType[];
  permissionCode?: string;
}
interface DropdownType {
  children: React.ReactNode;
  dataSource: DataSourceType[];
  placement?: dropPlacement;
  mt?: string;
  onSelect?: (newValue?: any) => void;
  onOpenChange?: (newValue?: boolean) => void;
  dropdownBoxClassName?: string;
}
const CustomDropdown = (props: DropdownType) => {
  const {
    children,
    dataSource = [],
    placement = "bottomRight",
    onSelect,
    onOpenChange,
    mt = "0rem",
    dropdownBoxClassName = "",
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const custom = useGetColor();

  // 改变选中状态
  const updateItemCheck = useMemoizedFn((listData, item: DataSourceType) => {
    setOpen(false);
    onSelect?.(item);
    onOpenChange?.(false);
  });

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
    onOpenChange?.(newOpen);
  };
  const PopoverItem = (item: DataSourceType, index: number) => {
    return (
      <div
        key={item?.id}
        onClick={() => item.canSelect && updateItemCheck(dataSource, item)}
        // hover:bg-primary/20 hover:text-primary
        className={clsx(
          "h-[2.25rem] flex items-center rounded-[4px] px-[0.9375rem] overflow-hidden text-ellipsis whitespace-nowrap text-[0.875rem] leading-[1.25rem] ",
          item.isCheck
            ? "bg-selectedColor text-primary cursor-pointer"
            : item.canSelect
              ? "hover:bg-selectedColor cursor-pointer text-cMainTxt"
              : "cursor-not-allowed text-cAssistTxt"
          // index < dataSource.length - 1 ? "mb-[8px]" : ""
        )}
      >
        {isExist(item?.icon) && (
          <span className="mr-[0.5rem] flex justify-center items-center">
            {isFunction(item.icon)
              ? item.icon(
                  item.isCheck
                    ? "var(--color-primary)"
                    : "var(--color-textAssist)"
                )
              : item.icon}
          </span>
        )}
        <span>{item.label}</span>
      </div>
    );
  };
  return (
    <Popover
      overlayClassName="pop-container"
      arrow={false}
      placement={placement}
      overlayInnerStyle={{
        borderRadius: "0 0 6px 6px",
        boxShadow: "0px 14px 21.6px 11px rgba(0, 0, 0, 0.05)",
      }}
      // @ts-ignore
      getPopupContainer={(trigger) => trigger.parentElement}
      trigger="hover"
      open={open}
      onOpenChange={handleOpenChange}
      content={
        <div
          style={{
            marginTop: mt,
          }}
          className={clsx(
            `flex flex-col rounded-[6px] bg-dropdownBackground px-2 py-2 select-none min-w-[180px]`
          )}
        >
          {dataSource.map((item, index) => {
            return (
              <div key={item.id}>
                {item.children ? (
                  <div>
                    {PopoverItem(item, index)}
                    <div className="ml-[26px]">
                      {item.children.map((itemC, index) => {
                        return PopoverItem(itemC, index);
                      })}
                    </div>
                  </div>
                ) : (
                  PopoverItem(item, index)
                )}
              </div>
            );
          })}
        </div>
      }
    >
      {children}
    </Popover>
  );
};

export default CustomDropdown;
