import { service } from "common";
import { AUTH_TOKEN } from "common/config";
export const getTripartiteAgreement = (params: { agreementType: string }) => {
  return service.get<any>("/permission-account/agreement/info/get", params);
};
export const oAuthLogin = (params: any) => {
  return service.post<any>("/permission-auth/v1/oauth/login", params);
};

export const stationList = () => {
  return new Promise((resolve, reject) => {
    fetch("/permission-account/oauth/getOwnerStationsBasicInfo", {
      method: "GET",
      headers: {
        Authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
        "Content-Type": "application/json", // 设置请求头，指定发送的数据格式为 JSON
      },
    })
      .then((response) => response.json())
      .then((r) => resolve(r))
      .catch((error) => reject(error));
  });
};

export const getInterfaceTypeList = () => {
  return new Promise((resolve, reject) => {
    fetch("/permission-account/oauth/getInterfaceTypeList", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
      },
    })
      .then((response) => response.json())
      .then((r) => resolve(r))
      .catch((error) => reject(error));
  });
};
