import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig} from "axios";
interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  isNeedQueue?: boolean;
}
interface PendingRequest {
  cancel: (message?: string) => void;
  config: any;  
}

interface PendingRequests {
  [key: string]: PendingRequest;
}

/**
 * @cancelRequest 取消重复请求
 * @method cancelRequest 开启自动取消重复请求
 * @method cancelAdd 添加请求至事件队列
 * @method cancelRemove 已请求的接口 从事件队列中删除
 * @method cancelError 错误处理
 * @method removeSpecifyRequest 删除指定请求
 */
const pending: PendingRequests = {}; // 请求队列
export const cancelRequest = () => {
  const cancelAdd = (config: CustomAxiosRequestConfig) => {
    // 所有请求添加到数组中
    if (!config.url) return
    const requestKey: string = config.url;
    // 如果存在相同的请求，取消它
    if (pending[requestKey] && config.isNeedQueue) {
      pending[requestKey].cancel('取消重复请求');
    }
    const source = axios.CancelToken.source();
    config.cancelToken = source.token;
    // 存储新的请求信息
    pending[requestKey] = {
      cancel: source.cancel,
      config
    };
  }
  const cancelRemove = (response: AxiosResponse) => {
    const requestKey = response.config.url || '';
    if (!requestKey) return
    if (response.config && (response.config as CustomAxiosRequestConfig)?.isNeedQueue && pending[requestKey]) {
      delete pending[requestKey];
    }

  }
  const cancelError = (error: AxiosError, callback: () => void) => {
    if (axios.isCancel(error)) {
      console.log('重复请求已取消:', error.message);
      return
    }
    callback()
  }
  const removeSpecifyRequest = (url: string) => {
    for (const p in pending) {
      if (pending[p] && p === url) {
        pending[p].cancel("取消重复请求");
      }
    }
  }
  return {
    cancelAdd,
    cancelRemove,
    cancelError,
    removeSpecifyRequest,
    cancelRequest,
  }
};
