import { Component } from "react";
import { withTranslation } from "react-i18next";
import "./index.css";
import { systemIsAndroidOrIosOrPc } from "common";
import { appUrl } from "./services";

type StateTy = {
  [key in "iOS" | "Android"]: {
    version: string;
    releaseType?: string;
    resp: {
      android: string;
      appStore: string;
      googlePlay: string;
    };
  };
} & {
  // [key: string]: InitDataTy;
  title: boolean;
  model: string;
};

class SharePage extends Component<
  {
    t: any;
  },
  StateTy
> {
  constructor(props: any) {
    super(props);
    const initData = {
      version: "",
      releaseType: "",
      resp: {
        android: "",
        appStore: "",
        googlePlay: "",
      },
    };
    this.state = {
      title: true,
      model: "",
      ["iOS"]: {
        ...initData,
      },
      ["Android"]: {
        ...initData,
      },
    };
  }
  async autoGetAddress(terminalType: string) {
    const res = await appUrl({ terminalType });
    return res.data;
  }
  async autoClick() {
    const [, urlParams] = window.location.href.split("?");
    console.log(systemIsAndroidOrIosOrPc(), "systemIsAndroidOrIosOrPc");
    const resp = await this.autoGetAddress("");
    switch (systemIsAndroidOrIosOrPc()) {
      case "iOS": {
        if (resp) {
          this.setState({
            model: "iOS",
            ["iOS"]: { version: this.props.t("versionApple"), resp },
          });
        }
        break;
      }
      case "Android": {
        if (resp) {
          let releaseType = "in"; // 应用内安卓（应用商店）
          if (urlParams === "prodsh") {
            releaseType = "out"; // Apk等等
          }
          this.setState({
            model: "Android",
            ["Android"]: { version: this.props.t("versionAndroid"), resp, releaseType },
          });
        }
        break;
      }
      case "PC": {
        if (resp) {
          let releaseType = "in"; // 应用内安卓（应用商店）
          if (urlParams === "prodsh") {
            releaseType = "out"; // Apk等等
          }
          this.setState({
            model: "PC",
            ["iOS"]: { version: this.props.t("versionApple"), resp },
            ["Android"]: { version: this.props.t("versionAndroid"), resp, releaseType },
          });
        }
        break;
      }
      default: {
        this.setState({
          title: false,
          model: "",
        });
      }
    }
  }
  componentDidMount(): void {
    this.autoClick();
  }
  render() {
    return (
      <div className="bck" style={{ height: window.innerHeight }}>
        <div className="Atmoce"></div>
        <div className="box">
          <div
            style={{
              fontSize: 20,
              fontWeight: 600,
              textAlign: "center",
              color: "#18191A",
            }}
          >
            {this.state.title ? `Atmozen ${this.props.t("download")}` : this.props.t("scandownload")}
            <br />
            <p className="des">Clean Energy For Better Life</p>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "100%", marginTop: 50 }}>
          {(this.state.model === "iOS" || this.state.model === "PC") && this.state.iOS.resp.appStore && (
            <>
              <div className="modelType">IOS</div>
              <>
                <div className="secBox">
                  <div className="downtype" />
                  <div style={{ display: "inline-flex", flexDirection: "column" }}>
                    <div style={{ fontSize: 18, color: "#18191A" }}>App Store</div>
                    <div style={{ fontSize: 14, color: "#84878C" }}>{this.state.iOS.version}</div>
                  </div>
                  <div
                    onClick={() => {
                      window.open(this.state.iOS.resp.appStore);
                    }}
                    className="btns"
                  >
                    {this.props.t("open")}
                  </div>
                </div>
                <div className="deviderLine" />
              </>
            </>
          )}
          {(this.state.model === "Android" || this.state.model === "PC") &&
            (this.state.Android.resp.googlePlay || this.state.Android.resp.android) && (
              <>
                <div className="modelType">Android</div>
                {this.state.Android.releaseType === "in" && (
                  <>
                    <div className="secBox">
                      <div className="downtype1" />
                      <div style={{ display: "inline-flex", flexDirection: "column" }}>
                        <div style={{ fontSize: 18, color: "#18191A" }}>Google Play</div>
                        <div style={{ fontSize: 14, color: "#84878C" }}>{this.state.Android.version}</div>
                      </div>
                      <div
                        onClick={() => {
                          window.open(this.state.Android.resp.googlePlay);
                        }}
                        className="btns"
                      >
                        {this.props.t("open")}
                      </div>
                    </div>
                    <div className="deviderLine" />
                  </>
                )}
                {this.state.Android.releaseType === "out" && this.state.Android.resp.android && (
                  <div className="secBox">
                    <div className="downtype2" />
                    <div style={{ display: "inline-flex", flexDirection: "column" }}>
                      <div style={{ fontSize: 18, color: "#18191A" }}>{this.props.t("browser")}</div>
                      <div style={{ fontSize: 14, color: "#84878C" }}>{this.state.Android.version}</div>
                    </div>
                    <div
                      className="btns"
                      onClick={() => {
                        window.location.href = this.state.Android.resp.android;
                      }}
                    >
                      {this.props.t("open")}
                    </div>
                  </div>
                )}
              </>
            )}
        </div>
      </div>
    );
  }
}
const SharePageHtml = withTranslation()(SharePage);
export default SharePageHtml;
