import { NoOauthSvg } from "./svg/noOauthSvg";
import { BottomSvg } from "./svg/bottomSvg";
import { useEffect, useState } from "react";
import { ErrorSvg } from "./svg/errorSvg";
import { t } from "i18next";
import _ from "lodash";
import "./index.css";
import { useData } from "./hook/useData";
/**
 *@description:暂无权限、错误页面
 *@author: 清欢
 *@param:
 *@createTime: 2025-02-24 15:39:47
 */
const Noauthorization = () => {
  const { urlFunc } = useData(false);
  const [errorInfos, setErrorInfos] = useState<{
    code?: number;
    reason?: string;
    details?: string;
  }>();
  const getErrorInfos = async () => {
    const res = urlFunc();
    setErrorInfos(JSON.parse(decodeURI(JSON.stringify(res))));
  };
  useEffect(() => {
    getErrorInfos();
  }, []);
  return (
    <div className="h-full w-full flex flex-col justify-center items-center">
      {errorInfos ? <ErrorSvg /> : <NoOauthSvg />}
      <div style={{ marginTop: "-24px" }}>
        <BottomSvg />
      </div>
      {errorInfos ? (
        <div className="flex flex-col items-center">
          <div className="mb-1 text-base" style={{ color: "rgba(0, 4, 14, 0.80)" }}>
            {errorInfos.reason}
          </div>
          <div className="text-sm max-w-48" style={{ color: "rgba(96, 98, 102, 1)" }}>
            {errorInfos.details}
          </div>
        </div>
      ) : (
        <div className="text-base" style={{ color: "rgba(0, 4, 14, 0.80)" }}>
          {t("noauthorization")}
        </div>
      )}
    </div>
  );
};

export default Noauthorization;
