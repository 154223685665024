import React from "react";
import { isExist, mustArray } from "../../utils";
import { FocusText, FONT_WEIGHT, MinorText, TEXT_SIZE } from "../BasicText";

import clsx from "clsx";
import { t } from "i18next";
import FormWrapper from "../FormWrapper";
import { Checkbox, Popover } from "antd";
import { InputType } from "../QueryBar/getInput";
import { isFunction, isString } from "lodash";
import HighlightText from "../HighlightText";
interface TitleContentProps {
  src?: string;
  showImg?: boolean;
  title?: string | React.ReactNode;
  className?: string;
  focusTextClassName?: string;
  itemBoxStyle?: string;
  textSize?: string;
  color?: string;
}

export const TitleContent = ({
  src,
  showImg,
  title,
  className,
  focusTextClassName,
  itemBoxStyle,
  textSize = TEXT_SIZE.BASE,
  color,
}: TitleContentProps) => {
  return (
    <div className={clsx(className)}>
      <div className={clsx("flex justify-start gap-2", itemBoxStyle)}>
        <div className=" flex justify-start  pt-[3px]">
          {isExist(src) || showImg ? (
            <img src={src} alt="" className="object-cover w-5 h-5" />
          ) : null}
        </div>

        {isString(title) ? (
          <FocusText
            color={color}
            textSize={textSize}
            text={title}
            fontWeight={FONT_WEIGHT.MEDIUM}
            className={focusTextClassName}
          />
        ) : (
          title
        )}
      </div>
    </div>
  );
};

interface RemarkContentProps {
  title: string;
  remarks: string[];
}

export const RemarkContent = ({ title, remarks }: RemarkContentProps) => {
  return (
    <div className=" bg-selectedColor rounded-md">
      <div className=" px-4  py-4">
        <FocusText
          text={title}
          fontWeight={FONT_WEIGHT.MEDIUM}
          className=" block mb-2"
        />
        <div className=" flex flex-col gap-1">
          {mustArray(remarks).map((item, index) => {
            return (
              <div key={`${index + 1}`}>
                <MinorText text={item} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

interface ManyRemarkContentProps {
  title: string;
  remarks: {
    title: string;
    description: string;
  }[];
  className?: string;
}

export const ManyRemarkContent = ({
  remarks,
  title,
  className,
}: ManyRemarkContentProps) => {
  return (
    <div className={clsx("", className)}>
      <FocusText
        text={title}
        fontWeight={FONT_WEIGHT.MEDIUM}
        textSize={TEXT_SIZE.BASE}
        className=" mb-3 block"
      />
      <div className=" bg-selectedColor rounded-md">
        <div className="flex flex-col gap-y-3 px-5 py-5">
          {remarks.map((item, index) => {
            return (
              <div key={`${index + 1}`} className=" flex flex-col gap-y-1">
                <FocusText text={item.title} fontWeight={FONT_WEIGHT.MEDIUM} />
                <MinorText text={item.description} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

interface CheckAgreementProps {
  check: boolean;
  setCheck: (check: boolean) => void;
  text: any;
  setOpen?: (open: boolean) => void;
  open?: boolean;
  popoverProps?: any;
  textStyle?: React.CSSProperties;
  existProtocol?: boolean; //存在协议
  onClickTripart?: () => void; // 点击协议
}

export const CheckAgreement = ({
  check,
  setCheck,
  text,
  open,
  setOpen,
  popoverProps,
  textStyle,
  existProtocol = false,
  onClickTripart = () => {},
}: CheckAgreementProps) => {
  const onClick = () => {
    if (isFunction(setOpen)) {
      setOpen(false);
    }
    setCheck(!check);
  };
  return (
    <div className=" flex gap-[8px]">
      <div className="flex items-center h-[22px]">
        <Popover
          content="Thanks for using antd. Have a nice day !"
          placement="bottomLeft"
          trigger="click"
          arrow={{
            pointAtCenter: true,
          }}
          open={!!open}
          {...popoverProps}
        >
          <Checkbox
            className=" w-[16px] h-[16px]"
            onChange={onClick}
            checked={check}
          />
        </Popover>
      </div>
      {existProtocol ? (
        <HighlightText
          highlightTextStyle={{ color: "var(--color-primary)" }}
          style={{ color: "var(--color-textMain)" }}
          text={text ?? t("loginAgree")}
          clickableIndexs={[0, 1]}
          onClickIndex={onClickTripart}
        />
      ) : (
        <div
          style={{
            height: "100%",
            fontSize: 14,
            lineHeight: "23px",
            ...textStyle,
          }}
          className="cursor-pointer"
          onClick={onClick}
        >
          {text}
        </div>
      )}
    </div>
  );
};

interface VerifyFormProps {
  check: boolean;
  setCheck: (check: boolean) => void;
  description: string;
  checkDescription: string;
  presetText: string;
  requiredName?: string;
  form: any;
}

export const VerifyForm = ({
  check,
  setCheck,
  description,
  form,
  checkDescription,
  presetText,
  requiredName,
}: VerifyFormProps) => {
  return (
    <div>
      <div className=" mt-7">
        <FocusText
          text={description || t("userInfoIdentityVerificationNeeded")}
          fontWeight={FONT_WEIGHT.MEDIUM}
        />
        <FormWrapper
          formStyle={{
            gridTemplateColumns: "1fr",
          }}
          form={form}
          formItems={[
            {
              name: "password",
              required: true,
              inputType: InputType.Password,
              requiredName: requiredName || t("comPassword"),
              inputOptions: {
                placeholder: presetText || t("comInputLoginPwd"),
              },
              options: {
                style: {
                  margin: "11px 0",
                },
              },
            },
          ]}
        ></FormWrapper>
      </div>
      <CheckAgreement
        check={check}
        setCheck={setCheck}
        text={checkDescription || t("userInfoAgreeToDeactivate")}
      />
    </div>
  );
};
