import { BottomSvg } from "../svg/bottomSvg";
import { NulldataSvg } from "../svg/nullData";
import { t } from "i18next";

export const Empty = () => {
  return (
    <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
      <NulldataSvg />
      <div style={{ marginTop: "-24px" }}>
        <BottomSvg />
      </div>
      <div style={{ color: "rgba(0, 4, 14, 0.80)", fontSize: "16px" }}>{t("nulStation")}</div>
    </div>
  );
};
