import React from "react";
export interface CardList {
  name: string;
  describle: string;
  iconUrl: React.ReactElement;
  interfaceType?: string;
  oauthSelected?: boolean;
}
export enum Authority {
  basic = "basic",
  control = "control",
}
export enum SwitchType {
  auth = 1,
  support = 2,
}
export type ParamsType = "OAuth_Client" | "redirect_uri" | "response_type";
export interface StationType {
  name: string;
  oauthSelected?: boolean;
  id?: number;
}
