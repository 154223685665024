// 多语言
export const CHANGE_LANG = "changeLang";
// 用户Token
export const USER_TOKEN = "userToken";
// 用户Token
export const AUTH_TOKEN = "authorization";
// 用户信息
export const USER_INFO = "userInfoKey";
// 登录信息
export const LOGIN_INFO = "loginInfo";
// 项目的基础路由前缀
export const BASE_PREFIX = "/energy";
// 高德地图的key
export const GAODE_KEY = "2d2b7ed1f312078440c9b07e0a475814";
export const GAODE_SAFETY_KEY = "ecf4b24f452b6297c585c28406cd4d28";

//电站信息
export const STATION_INFO = "/plant/overview";
// 设备列表
export const DEVICE_LIST = "/plant/device";
// 设备详情
export const DEVICE_VIEW = "/plant/deviceView";
// 诊断列表
export const POWER_PLANT_DX_LIST = "/powerPlantDxList";
// 诊断详情
export const POWER_PLANT_DX_VIEW = "/powerPlantDxView";
// 自动刷新告警列表
export const ALARM_REFRESH = "alarmRefresh";

export const BREAD_CRUMB_LIST = [
  "/account/staff",
  "/account/user/management",
  "/child/energy/account/company/info",
  "/child/energy/account/installerManagement/list",
  "/child/energy/account/InstallerAccountManagement/list",
  "/child/energy/sys/upgrad/package",
  "/child/energy/sys/upgrad/auto/upgrade",
  "/child/energy/sys/upgrad/device",
  "/child/energy/sys/upgrad/gatewayDisposition",
  "/child/energy/sys/upgrad/upgradeNotification",
  "/child/energy/sys/helpConfiguration/collectingFeedbackList",
  "/child/energy/sys/helpConfiguration/videoInstructionList",
  "/child/energy/sys/helpConfiguration/warranty",
  "/child/energy/sys/helpConfiguration/faqInput",
  "/child/energy/sys/helpConfiguration/documentManagement",
  "/child/energy/sys/device/log",
  "/child/energy/sys/device/log/detail",
  "/child/energy/sys/logManagement/operationLogs",
  "/child/energy/sys/logManagement/securityLogs",
  "/child/energy/sys/logManagement/runningLogs",
  "/child/energy/sys/messageManagement/sendAnnouncements",
  "/child/energy/sys/messageManagement/announcementMessage",
  "/sys/role/management",
  "/sys/menu/management",
  "/sys/demo/management",
  "/child/energy/sys/openApi",
  "/child/energy/sys/deviceReplacementRecord",
  "/child/energy/sys/Oauth",
  "/sys/expand/management",
];
