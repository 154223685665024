import React from "react";
import { Switch, SwitchProps, theme } from "antd";
const { useToken } = theme;

interface CustomSwitchProps extends SwitchProps {
  checkedBg?: string;
  noCheckedBg?: string;
  checked: boolean;
}

const CustomSwitch = (props: CustomSwitchProps) => {
  const { token } = useToken();
  const {
    checkedBg = "var(--color-primary)", //这个在组件上不存在， 直接扩展放下去会报错
    noCheckedBg = "var(--color-textDisable)",
    checked = false,
    ...extraProps
  } = props;
  return (
    <Switch
      style={{
        backgroundColor: checked ? `${checkedBg}` : noCheckedBg,
      }}
      checked={checked}
      className={`${checkedBg ? `hover:!bg-[${checkedBg}] hover:opacity-45` : ''}`}
      {...extraProps}
    />
  );
};

export default CustomSwitch;
