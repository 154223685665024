import { StationType } from "../model/type";
import CustomSwitch from "./Switch/CustomSwitch";
import { t } from "i18next";
/**
 *@description:支持电站授权
 *@author: 清欢
 *@param:
 *@createTime: 2025-02-24 19:03:26
 */
export const StationList = (props: { data: StationType[]; onChange: (i: StationType) => void }) => {
  const { onChange, data } = props;
  return (
    <div>
      <div className="mb-2 mt-3" style={{ color: "rgba(132, 135, 140, 1)", fontSize: "14px" }}>
        {t("oauthStation")}
      </div>
      <div className="align-center flex flex-col p-3  rounded-md bg-white" style={{ maxHeight: "170px", overflow: "auto" }}>
        {data.map((v, i) => {
          return (
            <div key={v.name + v.id}>
              <div className="flex items-center justify-between">
                <div className="ml-1 mr-4 flex-col justify-start items-start w-full">
                  <div className="mb-1" style={{ color: "rgba(96, 98, 102, 1)", fontSize: "14px" }}>
                    {v.name}
                  </div>
                </div>
                <CustomSwitch
                  value={v.oauthSelected ?? false}
                  onChange={(e) => {
                    onChange({ ...v, oauthSelected: e });
                  }}
                />
              </div>
              {data.length - 1 !== i && <div style={{ border: "0.5px solid rgba(234, 235, 237, 1)" }} className="mb-3 mt-3" />}
            </div>
          );
        })}
      </div>
    </div>
  );
};
