import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import type { RouteObject as RouteObjectDom } from "react-router-dom";
import AccountActive from "@/pages/accountActive";
import Sharepage from "@/pages/share";
import OauthTest from "@/pages/oauthTest";
import Loginout from "@/pages/loginout";
import Authorization from "@/pages/authorizationlogin/authorization";
import Noauthorization from "@/pages/authorizationlogin/noauthorization";
import Authorizationlogin from "@/pages/authorizationlogin";
import lazyLoad from "./utils/lazyLoad";
export interface MetaProps {
  keepAlive?: boolean;
  requiresAuth?: boolean;
  title?: string;
  key?: string;
}

export interface RouteObject {
  caseSensitive?: boolean;
  children?: RouteObject[];
  element?: React.ReactNode;
  index?: boolean;
  path?: string;
  meta?: MetaProps;
  isFrame?: string;
}

// eslint-disable-next-line react-refresh/only-export-components
export const rootRouter: RouteObject[] = [
  {
    path: "/login",
    element: lazyLoad(React.lazy(() => import("@/pages/login"))),
    meta: {
      requiresAuth: false,
      title: "登录页",
      key: "login",
    },
    // children: [
    //   {
    //     path: "/forgotPassword",
    //     element: lazyLoad(React.lazy(() => import("@/pages/forgotPassword"))),
    //     meta: {
    //       requiresAuth: false,
    //       title: "注册",
    //       key: "loginll",
    //     },
    //   },
    // ],
  },
  {
    path: "/forgotPassword",
    element: lazyLoad(React.lazy(() => import("@/pages/forgotPassword"))),
    meta: {
      requiresAuth: false,
      title: "忘记密码",
      key: "forgotPassword",
    },
  },
  {
    path: "/registered",
    element: lazyLoad(React.lazy(() => import("@/pages/registered"))),
    meta: {
      requiresAuth: false,
      title: "注册",
      key: "registered",
    },
  },
  {
    path: "/systemUpgrade",
    element: lazyLoad(React.lazy(() => import("@/pages/systemUpgrade"))),
    meta: {
      requiresAuth: false,
      title: "系统升级",
      key: "systemUpgrade",
    },
  },
  {
    path: "/",
    element: <Navigate to="/child/energy/home" />,
  },

  {
    path: "/test",
    element: lazyLoad(React.lazy(() => import("@/layouts"))),
    children: [
      {
        path: "",
        element: lazyLoad(React.lazy(() => import("@/pages/test"))),
        meta: {
          requiresAuth: false,
          title: "测试页",
          key: "test",
        },
      },
    ],
  },

  {
    path: "/account/staff",
    element: lazyLoad(React.lazy(() => import("@/layouts"))),
    children: [
      {
        path: "",
        element: lazyLoad(React.lazy(() => import("@/pages/account/staffAccount"))),
        meta: {
          requiresAuth: false,
          title: "员工账号",
          key: "accountStaff",
        },
      },
    ],
  },

  {
    path: "/sys/menu/management",
    element: lazyLoad(React.lazy(() => import("@/layouts"))),
    children: [
      {
        path: "",
        element: lazyLoad(React.lazy(() => import("@/pages/system/menuManagement"))),
        meta: {
          requiresAuth: true,
          title: "菜单管理",
          key: "menuManagement",
        },
      },
    ],
  },

  {
    path: "/sys/role/management",
    element: lazyLoad(React.lazy(() => import("@/layouts"))),
    children: [
      {
        path: "",
        element: lazyLoad(React.lazy(() => import("@/pages/system/roleManagement"))),
        meta: {
          requiresAuth: true,
          title: "角色管理",
          key: "roleManagement",
        },
      },
    ],
  },
  {
    path: "/sys/expand/management",
    element: lazyLoad(React.lazy(() => import("@/layouts"))),
    children: [
      {
        path: "",
        element: lazyLoad(React.lazy(() => import("@/pages/system/expandManagement"))),
        meta: {
          requiresAuth: true,
          title: "扩展权限",
          key: "expandManagement",
        },
      },
    ],
  },
  {
    path: "/sys/demo/management",
    element: lazyLoad(React.lazy(() => import("@/layouts"))),
    children: [
      {
        path: "",
        element: lazyLoad(React.lazy(() => import("@/pages/system/demoManagement"))),
        meta: {
          requiresAuth: true,
          title: "演示电站",
          key: "demoManagement",
        },
      },
    ],
  },

  {
    path: "/account/user/management",
    element: lazyLoad(React.lazy(() => import("@/layouts"))),
    children: [
      {
        path: "",
        element: lazyLoad(React.lazy(() => import("@/pages/account/userManagement"))),
        meta: {
          requiresAuth: true,
          title: "用户管理",
          key: "userManagement",
        },
      },
    ],
  },

  {
    path: "/account/activation",
    element: <AccountActive />,
    meta: {
      requiresAuth: false,
      title: "账号激活",
      key: "accountActive",
    },
  },

  {
    path: "/share",
    element: <Sharepage />,
    meta: {
      requiresAuth: false,
      title: "分享",
      key: "share",
    },
  },
  {
    path: "/oauthTest",
    element: <OauthTest />,
    meta: {
      requiresAuth: false,
      title: "auth测试",
      key: "oauthTest",
    },
  },
  {
    path: "/loginout",
    element: <Loginout />,
    meta: {
      requiresAuth: false,
      title: "注销",
      key: "loginout",
    },
  },
  {
    path: "/authorizationlogin",
    element: <Authorizationlogin />,
    meta: {
      requiresAuth: false,
      title: "授权登录",
      key: "Authorizationlogin",
    },
  },
  {
    path: "/authorization",
    element: <Authorization />,
    meta: {
      requiresAuth: true,
      title: "账号授权",
      key: "authorization",
    },
  },
  {
    path: "/noPermission",
    element: <Noauthorization />,
    meta: {
      requiresAuth: true,
      title: "暂无权限",
      key: "noPermission",
    },
  },

  {
    path: "/child/energy/*",
    element: lazyLoad(React.lazy(() => import("@/layouts"))),
  },

  {
    path: "/account/personalCenter/info",
    element: lazyLoad(React.lazy(() => import("@/layouts"))),
    children: [
      {
        path: "/account/personalCenter/info",
        element: lazyLoad(React.lazy(() => import("@/pages/account/personalCenter"))),
        meta: {
          requiresAuth: false,
          title: "基本信息",
          key: "personalCenter",
        },
      },
    ],
  },

  // {
  //   path: "/child/*",
  //   element: lazyLoad(React.lazy(() => import("@/layouts"))),
  // },

  // {
  //   path: "/child",
  //   element: lazyLoad(React.lazy(() => import("@/layouts"))),
  //   children: [{ path: "energy/*"}],
  // },

  {
    path: "/404",
    element: lazyLoad(React.lazy(() => import("@/pages/404"))),
  },

  {
    path: "*",
    element: <Navigate to="/404" />,
  },
];

const Router = () => {
  const routes = useRoutes(rootRouter as RouteObjectDom[]);
  return routes;
};

export default Router;
