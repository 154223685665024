import { BrowserRouter } from "react-router-dom";
import Router from "@/routers/index";
import AuthRouter from "@/components/Auth";
import { CustomConfigProvider, themeConfig } from "common";
import { createContext, useEffect, useState, useLayoutEffect } from "react";
import { actions } from "@/micro/actions";
import { useAtom } from "jotai";
import { switchLang } from "./jotai/commonInfo";
import { BASE_PREFIX } from "common/config";
import { useTranslation } from "react-i18next";
import { GlobalNoData } from "common";
import { useLoginHandleData } from "./pages/login/hooks/useLoginHandleData";

interface ProviderType {
  offGlobalStateChange?: any;
  onGlobalStateChange?: any;
  setGlobalState?: any;
  getGlobalState?: any;
}
export const AppContext = createContext<ProviderType>({});
const getObj = () => {
  const currentTheme: "dark" | "light" = (window?.localStorage.getItem("currentTheme") as "dark" | "light") || "dark";
  return {
    components: themeConfig.default.themeDefault.components,
    token: {
      ...themeConfig.default.themeDefault.token,
      colorPrimary: currentTheme === "light" ? "#3E7EF8" : "#19A07B",
      fontFamily: `system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, 'Microsoft YaHei', "Helvetica Neue", Arial, sans-serif`,
    },
  };
};

function getOperatingSystem() {
  const platform = navigator.platform.toLowerCase();
  const macosPlatforms = ["macintosh", "macintel", "darwin"];

  if (macosPlatforms.includes(platform)) {
    return "mac";
  }

  return "win";
}

function App() {
  const { t } = useTranslation();
  const [switchLangState] = useAtom(switchLang);
  const [themeData, setThemeData] = useState(getObj());
  // const { getMenuList } = useLoginHandleData();
  useLayoutEffect(() => {
    const currentTheme: "dark" | "light" = (window?.localStorage.getItem("currentTheme") as "dark" | "light") || "dark";
    document.body.classList.add(`theme-${currentTheme}`);
    document.body.classList.add("theme-default");
  }, []);
  useEffect(() => {
    console.log(window.location, "pathnamepathname");

    const os = getOperatingSystem();
    if (os === "win") {
      document.body.classList.add("win");
    } else {
      document.body.classList.add("mac");
    }
    window.addEventListener("localStorageChange", (e: any) => {
      setThemeData(() => {
        return getObj();
      });
    });
  }, []);
  return (
    <AppContext.Provider value={actions}>
      <div className={`h-full w-full app-container text-cMainTxt`}>
        <BrowserRouter basename={BASE_PREFIX}>
          <CustomConfigProvider
            langType={switchLangState}
            // theme={{ algorithm: theme.darkAlgorithm }}
            theme={themeData}
            renderEmpty={GlobalNoData}
            // theme={themeList("dark")?.themeDefault}
          >
            <AuthRouter>
              <Router />
            </AuthRouter>
          </CustomConfigProvider>
        </BrowserRouter>
      </div>
    </AppContext.Provider>
  );
}

export default App;
