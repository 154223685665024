import React, { useState } from "react";
import { DatePicker } from "antd";
import { BoxConfig, extarPropsType } from "../getInput";
import { disableFutureDates, isFunction } from "../../../utils";
import { DateType, getFormat } from "../../../utils/DateUtils";
import DateSvg from "../../../svg/dateSvg";
import { t } from "i18next";

export const BasicDatePicker = (
  item: BoxConfig,
  { inputDefaultStyle }: extarPropsType
) => {
  const extarStyles = item?.inputOptions?.style || {};
  return (
    <DatePicker
      style={{ width: "100%", ...inputDefaultStyle, ...extarStyles }}
      placeholder={t("comChoose")}
      suffixIcon={<DateSvg />}
      format={getFormat(DateType.DAY)}
      disabledDate={
        !item.disableDate
          ? (current) => {
              return disableFutureDates(current);
            }
          : item.disableDate
      }
      {...item.inputOptions}
      onChange={(...prams) => {
        if (isFunction(item.inputOptions?.onChange))
          item.inputOptions.onChange(...prams);
      }}
    />
  );
};
