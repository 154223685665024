import { encryptPassword, getCurrentLanguage, localSet } from "common/utils";
import { useToAgreement } from "../login/hooks/useToAgreement";
import { Button, Form, Input, Row, message } from "antd";
import { AUTH_TOKEN, CHANGE_LANG } from "common/config";
import { useContext, useEffect, useState } from "react";
import { CHANGE_LANG_KEY } from "z/config/constant";
import { CompanySvg } from "./svg/companySvg";
import { mutationLogin } from "@/services/user";
import { useNavigate } from "react-router-dom";
import { CheckAgreement } from "common";
import { AppContext } from "@/App";
import i18n, { t } from "i18next";
import _ from "lodash";
import "./index.css";
import { oAuthLogin } from "./model/services";
/**
 *@description:对接三方平台落地页
 *@author: 清欢
 *@param:
 *@createTime: 2025-02-24 15:39:47
 */
const Authorizationlogin = () => {
  const { agreementContent } = useToAgreement({ h5TxtColor: "rgba(0, 4, 14, 0.40)" });
  const [form] = Form.useForm();
  const [agreementCheck, setAgreementCheck] = useState(false);
  const [openAgreementTips, setOpenAgreementTips] = useState(false);
  const ctx = useContext(AppContext);
  const navigate = useNavigate();
  // 根据url传递的参数来决定支持的国家的语言
  const supportLanguage = () => {
    const val = navigator.language || "sysWebLanguage";
    const currentLanguage = getCurrentLanguage(val);
    i18n.changeLanguage(currentLanguage);
    localSet(CHANGE_LANG, currentLanguage);
    localSet(CHANGE_LANG_KEY, currentLanguage); // 设置缓存里面的 languageKey
    ctx.setGlobalState({
      curLang: currentLanguage,
    });
  };
  useEffect(() => {
    localStorage.setItem("currentTheme", "light");
    supportLanguage();
  }, []);
  /**
   *@description:表单提交登陆
   *@author: 清欢
   *@param:
   *@createTime: 2025-02-24 15:37:50
   */
  const onFinish = async (values: { username: string; password: string }) => {
    const { username, password } = values;
    if (!username) {
      return message.error(t("loginNamePlaceholder"));
    }
    if (!password) {
      return message.error(t("loginPwdPlaceholder"));
    }
    if (!agreementCheck) {
      return;
    }
    try {
      const mutationResult = await oAuthLogin({
        username: username!.trim(),
        encrypted: true,
        password: encryptPassword(password!.trim()),
        appType: "OAuth_Client",
      });
      if (mutationResult.code === 200) {
        const { token, authorizeConfirmUrl = "/authorization" } = mutationResult.data || {};
        localSet(AUTH_TOKEN, `Bearer ${token}` || "");
        let url = authorizeConfirmUrl;
        const basic = "/energy";
        if (authorizeConfirmUrl.includes(basic) && authorizeConfirmUrl.startsWith(basic)) {
          const [, u2] = authorizeConfirmUrl.split(basic);
          url = u2;
        }
        navigate(url);
      }
    } catch (err) {
      console.log("============>>", err);
    }
  };
  return (
    <div id="authorizelogin" className="pt-2 w-full h-full overflow-y-auto">
      <div className="mb-14 w-[92%] ml-[4%]">
        <div className="mb-14 mt-14">
          <CompanySvg />
        </div>
        <div className="align-center w-full">
          <Form name="validateOnly" layout="vertical" autoComplete="off" onFinish={_.throttle(onFinish, 3000)} form={form}>
            <Form.Item
              name="username"
              label={<span style={{ color: "rgba(0, 4, 14, 1)" }}>{t("loginName")}</span>}
              labelCol={{ className: "custom-label text-white" }}
            >
              <Row className="-mt-1 oauth-acount">
                <Input
                  className="w-full h-12 p-0 m-0 pl-3 pr-3 bg-['fff'] border border-[#EEF2F7] border-solid"
                  placeholder={t("comEmail")}
                  style={{
                    color: "rgba(0, 4, 14, 1)",
                  }}
                />
              </Row>
            </Form.Item>
            <Form.Item
              name="password"
              label={<span style={{ color: "rgba(0, 4, 14, 1)" }}>{t("loginPwd")}</span>}
              labelCol={{ className: "custom-label" }}
            >
              <Row className="flex-row -mt-1">
                <Input.Password
                  type="password"
                  className="w-full h-12 p-0 m-0 pl-3 pr-3 flex items-center justify-center bg-['fff'] border border-[#EEF2F7] border-solid"
                  placeholder={t("comPassword")}
                  style={{
                    lineHeight: "48px",
                    color: "rgba(0, 4, 14, 1)",
                  }}
                />
              </Row>
            </Form.Item>
            <CheckAgreement
              check={agreementCheck}
              setCheck={setAgreementCheck}
              textStyle={{ width: "100%" }}
              open={openAgreementTips}
              setOpen={setOpenAgreementTips}
              popoverProps={{
                content: agreementContent,
              }}
              text={agreementContent}
            />
            <Form.Item>
              <Button
                className="w-full pt-0 pb-0 pl-5 pr-5 mt-24 h-12 select-none border border-solid border-[transparent]"
                style={{
                  backgroundColor: agreementCheck ? "rgba(62, 126, 248, 1)" : "rgba(234, 235, 237, 1)",
                  color: agreementCheck ? "#fff" : "#A8ABB2",
                }}
                htmlType="submit"
              >
                {t("loginBtn")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Authorizationlogin;
