import { useLocation, Navigate } from "react-router-dom";
import { localGetString } from "@/utils";
import { USER_TOKEN } from "z/config/constant";
import { AUTH_TOKEN } from "common/config";
const AuthRouter = (props: { children: JSX.Element }) => {
  const { pathname } = useLocation();
  const whiteList = ["/account/activation", "/share", "/oauthTest", "/loginout", "/authorizationlogin", "/noPermission", "/authorization"];
  if (["/authorization"].includes(pathname) && !localGetString(AUTH_TOKEN)) return <Navigate to="/authorizationlogin" replace />;
  if (pathname && whiteList.includes(pathname)) return props.children;
  if (!["/login", "/registered", "/forgotPassword"].includes(pathname) && !localGetString(USER_TOKEN)) return <Navigate to="/login" replace />;
  // if (pathname === "/login" && localGetString(USER_TOKEN)) return <Navigate to="/" replace />;
  return props.children;
};

export default AuthRouter;
