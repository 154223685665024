import React, { useContext } from "react";
import { Popconfirm, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import {
  ModifySvg,
  ClearSvg,
  TranslationSvg,
  EnableSvg,
  DisableSvg,
  HistorySvg,
  ManagementSvg,
  DisplaySvg,
  HiddenSvg,
  OauthSvg,
} from "../../svg/modifySvg";
import { OPERATE_BUTTON_TYPE } from "../../utils/enums";
import DownloadSvg from "../../svg/downloadSvg";
import { OperateBoxContext } from "./OperateBox";
import { isExist } from "../../utils";
import clsx from "clsx";
import CustomSwitch from "../CustomSwitch";

interface OperateButtonProps {
  onClick?: () => void;
  onConfirm?: () => void;
  className?: string;
  title?: string;
  type?: OPERATE_BUTTON_TYPE;
  svgContent?: React.ReactNode;
  color?: string;
  btnPerms?: string;
  disabled?: boolean;
  switchStatus?: boolean;
  switchChange?: () => void;
  switchCheck?: boolean;
}

const OperateButton = ({
  onClick,
  onConfirm,
  className = "mt-10",
  title,
  svgContent,
  type = OPERATE_BUTTON_TYPE.MODIFY,
  color = "",
  btnPerms = "",
  disabled = false,
  switchStatus = false,
  switchChange = () => {},
  switchCheck = false,
}: OperateButtonProps) => {
  const { t } = useTranslation();
  const { permsList } = useContext(OperateBoxContext);
  // 是否有按钮权限
  const isBtnPerms = isExist(btnPerms)
    ? btnPerms && permsList.includes(btnPerms)
    : true;
  const showBtnFlag = isBtnPerms && !disabled;
  const currentColor =
    color || showBtnFlag ? "var(--color-primary)" : "var(--color-textDisable)";
  // 按钮名称
  const TITLE_CONTENT = {
    [OPERATE_BUTTON_TYPE.MODIFY]: t("comModify"),
    [OPERATE_BUTTON_TYPE.CLEAR]: t("comDelete"),
    [OPERATE_BUTTON_TYPE.TRANSLATION]: t("classTranslation"),
    [OPERATE_BUTTON_TYPE.ST]: t("comEnable"),
    [OPERATE_BUTTON_TYPE.SP]: t("comDisable"),
    [OPERATE_BUTTON_TYPE.VIEW]: t("comDetail"),
    [OPERATE_BUTTON_TYPE.DOWNLOAD]: t("comDownload"),
    [OPERATE_BUTTON_TYPE.HIDDEN]: t("comHidden"),
    [OPERATE_BUTTON_TYPE.DISPLAY]: t("comDisplay"),
    [OPERATE_BUTTON_TYPE.RANGE]: t("黑名单"),
    [OPERATE_BUTTON_TYPE.DELETE]: t("comDelete"),
    [OPERATE_BUTTON_TYPE.OAUTH]: t("授权"),
    [OPERATE_BUTTON_TYPE.SWITCH]: switchStatus
      ? t("comEnable")
      : t("comDisable"),
    // [OPERATE_BUTTON_TYPE.MORE]: t("comDelete"),
  };
  //按钮样式
  const BUTTON_CONTENT = {
    [OPERATE_BUTTON_TYPE.MODIFY]: <ModifySvg color={currentColor} />,
    [OPERATE_BUTTON_TYPE.DOWNLOAD]: <DownloadSvg color={currentColor} />,
    [OPERATE_BUTTON_TYPE.CLEAR]: !showBtnFlag ? (
      <ClearSvg color={currentColor} />
    ) : (
      <Popconfirm
        placement="topRight"
        title={t("comDelete")}
        description={t("comDelInfo")}
        zIndex={2000}
        onConfirm={() => {
          if (!showBtnFlag) return;
          onConfirm?.();
        }}
        okText={t("comConfirm")}
        cancelText={t("comCancel")}
      >
        <Tooltip
          zIndex={1000}
          placement="top"
          title={t("comDelete")}
          trigger={"hover"}
        >
          <div className="cursor-pointer flex items-center">
            <ClearSvg Svg color={currentColor} />
          </div>
        </Tooltip>
      </Popconfirm>
    ),
    [OPERATE_BUTTON_TYPE.TRANSLATION]: <TranslationSvg color={currentColor} />,
    [OPERATE_BUTTON_TYPE.ST]: <EnableSvg color={currentColor} />,
    [OPERATE_BUTTON_TYPE.SP]: <DisableSvg color={currentColor} />,
    [OPERATE_BUTTON_TYPE.VIEW]: <HistorySvg color={currentColor} />,
    [OPERATE_BUTTON_TYPE.RANGE]: <ManagementSvg color={currentColor} />,
    [OPERATE_BUTTON_TYPE.HIDDEN]: <HiddenSvg color={currentColor} />,
    [OPERATE_BUTTON_TYPE.DISPLAY]: <DisplaySvg color={currentColor} />,
    [OPERATE_BUTTON_TYPE.DELETE]: <ClearSvg color={currentColor} />,
    [OPERATE_BUTTON_TYPE.OAUTH]: <OauthSvg color={currentColor} />,
    [OPERATE_BUTTON_TYPE.SWITCH]: (
      <CustomSwitch
        disabled={!showBtnFlag}
        onChange={switchChange}
        size="small"
        checked={switchCheck}
      />
    ),
  };

  return (
    <>
      {type === "CLEAR" ? (
        <div className="w-[20px] h-[20px]">{BUTTON_CONTENT["CLEAR"]}</div>
      ) : (
        <Tooltip placement="top" title={title || TITLE_CONTENT[type]}>
          <div
            onClick={() => {
              if (!showBtnFlag) return;
              onClick && onClick?.();
            }}
            className={clsx(
              "flex items-center",
              showBtnFlag ? "cursor-pointer" : "cursor-not-allowed"
            )}
          >
            {svgContent || BUTTON_CONTENT[type]}
          </div>
        </Tooltip>
      )}
    </>
  );
};
export default OperateButton;
