import { useToAgreement } from "../login/hooks/useToAgreement";
import { StationList } from "./component/StationList";
import { CardList } from "./component/Card";
import { SwitchType } from "./model/type";
import { Empty } from "./component/Empty";
import { useData } from "./hook/useData";
import { CheckAgreement } from "common";
import { Button, message, Spin } from "antd";
import { useState } from "react";
import { t } from "i18next";
import _ from "lodash";
import "./index.css";
const Authorization = () => {
  const { dataCard, goNextPage, setDataCard, stationData, setStationData, stationINterFaceStatus } = useData();
  const { agreementContent } = useToAgreement({ text: t("apiAgree"), type: "oauth_client", h5TxtColor: "rgba(132, 135, 140, 1)" });
  const [agreementCheck, setAgreementCheck] = useState(false);
  const [openAgreementTips, setOpenAgreementTips] = useState(false);
  // 改变switch状态--类型  1为授权类型 2为支持电站
  const onChange = (item: any, type: SwitchType) => {
    const data = type === SwitchType.auth ? dataCard : stationData;
    const setFunc = type === SwitchType.auth ? setDataCard : setStationData;
    const res = data.map((v: any) => {
      return (type === SwitchType.auth && v.interfaceType === item.interfaceType) ||
        (type === SwitchType.support && v.name === item.name && v.id === item.id)
        ? item
        : v;
    });
    setFunc(res);
  };
  // 滑动到底部
  const addContent = () => {
    const scrollBox = document.getElementById("authorize");
    // 滚动到最底部
    if (scrollBox) {
      scrollBox.scrollTop = scrollBox?.scrollHeight;
    }
  };
  /**
   *@description:提交数据授权
   *@author: 清欢
   *@param:
   *@createTime: 2025-02-26 11:30:20
   */
  const submit = async () => {
    const suupportStation = stationData.filter((v) => v.oauthSelected);
    if (suupportStation.length === 0) {
      return message.error(t("authorizationStation"));
    }
    const result = suupportStation.map((v) => {
      return {
        stationId: v.id,
        interfaceTypeList: dataCard.filter((v) => v.oauthSelected).map((v) => v.interfaceType),
      };
    });
    goNextPage({ result })
      .then((res: any) => {
        if (res.redirected && res.status === 200) {
          window.location.href = res.url;
        }
      })
      .catch((err) => {
        console.log("===========================>>>222", err);
      });
  };
  return !stationINterFaceStatus ? (
    <Spin className="w-full h-full" style={{ top: "50%", position: "absolute" }} />
  ) : stationINterFaceStatus && stationData.length === 0 ? (
    <Empty />
  ) : (
    <div id="authorize" className="pt-2 h-full w-full overflow-auto">
      <div style={{ width: "92%", marginLeft: "4%" }}>
        <div className="mb-7 text-2xl mt-12">
          <div className="font-medium" style={{ color: "rgba(0, 4, 14, 1)" }}>
            {t("countOauth")}
          </div>
          <div className="text-sm" style={{ color: "rgba(96, 98, 102, 1)" }}>
            {t("Oauthing")}
          </div>
        </div>
        <CardList
          onChange={(val) => {
            onChange(val, SwitchType.auth);
          }}
          data={dataCard}
        />
        {stationData.length > 1 && (
          <StationList
            onChange={(val) => {
              onChange(val, SwitchType.support);
            }}
            data={stationData}
          />
        )}
        <div className="w-full mt-2 mb-5" style={stationData.length < 2 ? { position: "absolute", bottom: 0 } : {}}>
          <CheckAgreement
            check={agreementCheck}
            setCheck={(v) => {
              setAgreementCheck(v);
              addContent();
            }}
            textStyle={{ width: "100%" }}
            open={openAgreementTips}
            setOpen={setOpenAgreementTips}
            popoverProps={{
              content: agreementContent,
            }}
            text={agreementContent}
          />
          <Button
            disabled={!agreementCheck}
            onClick={submit}
            style={{
              padding: "0 20px",
              backgroundColor: agreementCheck ? "rgba(62, 126, 248, 1)" : "rgba(234, 235, 237, 1)",
              color: agreementCheck ? "#fff" : "#A8ABB2",
              border: "1px solid transparent",
              width: "100%",
              height: "50px",
              marginTop: "20px",
              fontSize: "16px",
            }}
          >
            {t("comSave")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Authorization;
